import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal} from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import InputGroup from 'react-bootstrap/InputGroup';

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsCardText,
  BsFillFileEarmarkTextFill,
  BsFillCloudRainHeavyFill,
  BsStickies
} from "react-icons/bs";
import { MdDelete } from "react-icons/md";

import SidebarMain from "./SidebarMain";
import { formatDateIndian } from "./utils";

const AddUpdateMyWorkLog = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
 

  let initialItems = [];
 
 
  const [items, setItems] = useState(initialItems);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    work_date: formatDate(new Date()),
    minute_spent: "",
    minute_remaining: "",
    hour_spent: "",
    hour_remaining: "",
    work_description: "",
    output: "",
  };

  const activityArr = [
    { value: 1, label: "Coding" },
    { value: 2, label: "Designing" },
    { value: 3, label: "Analyze" },
  ];

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState();
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState();
 
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
 
  const [toRender, setToRender] = useState(false);

 
  const [editAction, setEditAction] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);


  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  const [submitDelAction, setSubmitDelAction] = useState(false);
  const [dialogResStatus, setDialogResStatus] = useState("");
  const [showMsgTitle, setShowMsgTitle] = useState("");
  const [showMsgBody, setShowMsgBody] = useState("");
 
  const [activityIdSelect, setActivityIdSelect] = useState({});
  const [optActivity, setOptActivity] = useState(activityArr);

  const [fullResponse, setFullResponse] = useState({});

 

  const [actionVal, setActionVal] = useState('save-only');

  var validateErr = false;


  
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    
    if (name === "minute_spent" || name === "minute_remaining" || name === "hour_spent" || name === "hour_remaining")  {
      console.log("Inside check ******************");
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    
    else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

 
  


  useEffect(() => {
    if (!initialized.current) {
        console.log(
          "*************** useEffect::addUpdateTask called ##########################"
        );
  
        if (state === null) {
          navigate("/listMyWorkLogs");
        } else {
          setActivityIdSelect(optActivity[0]);
          console.log("PASSED STATE VALUE IN TASK Add/Update page : ", state);
           console.log("Inside else for view or edit ...");
              //setFormValues(state.passed_element);
          if (state.action === "add") {
           
            setIsReadOnly(true);
          }
          else {
            setFormValues(state.passed_element);
          }
          console.log("newObj :", formValues);
          if (state.action === "add") {
            setIsReadOnly(false);
          }
          setToRender(true);
        }
        
        (async() => {
         // await getStaticDataCreateTask();
        })();

        initialized.current = true;
      }
    
  }, []);

  /*
  const submitTaskvalues = async(e) => {
    console.log("submitTaskvalues called : ", e);
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/pmo/createTask";
      } else if (state.action === "view") {
        apiToCall = "/pmo/updateTask";
      } else {
        apiToCall = "/pmo/createTask";
      }

      var reqParam = {
        task_id: formValues["id"],
        task_summary: formValues["task_summary"],
        project_id: projectIdSelect.value,
        task_desc: formValues["task_description"] || "",
        assigned_to: assignToIdSelect.value,
        due_date: formValues['due_date'],
        priority_code: priorityIdSelect.value,
        status_code: statusIdSelect.value
      };
      
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setAlertPurpose('success');
        setAlertTitle('Success');
       
       
          setAlertBody(res.data.detail.data.message);
           / *
           if(state.action === "add"){
        }
        else{
          setAlertBody("Task Updated Successfully!");
        }
        //setAlertBody("Summary Updated Successfully!");
        * /
        setSubmitAction(false);
        setShowAlert(true);
       
      }
      else {
          setAlertPurpose('failure');
          setAlertTitle('Failed');
          if(state.action === "add"){
            setAlertBody("Task Save Failed!");
          }
          else{
            setAlertBody("Task Update Failed!");
          }
          //setAlertBody("Summary Update Failed!");
          setSubmitAction(false);
          setShowAlert(true);
          
        }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('failure');
      setAlertTitle('Failed');
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setSubmitAction(false);
      setShowAlert(true);
    }


  }
  */
  
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
      
    if (!values.work_description){ 
      errors.work_description = "Work Description is required!";
      validateErr = true;
    }

    if (!values.minute_spent){ 
      errors.minute_spent = "Time Spent is required!";
      validateErr = true;
    }
    if (!values.hour_spent){ 
      errors.hour_spent = "Time Spent is required!";
      validateErr = true;
    }


    /*
    else {
      errors.task_summary = "";
    }
    */

    


   
    return errors;
  };



 

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
 
  /*
  const deleteTask = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      
      let reqAddUpdateTask = {
        task_id: state.passed_element.id,
        
      };
      setSubmitDelAction(true);
      var res = await axios.post(client.domain + "/pmo/deleteTask", reqAddUpdateTask, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setAlertPurpose('deleted');
        setAlertTitle('Success');
        setAlertBody(res.data.detail.data.message);
        setDialogResStatus('success');
        setSubmitDelAction(false);
        setShowAlert(true);
        }
      else {
        setAlertPurpose('delete_failed');
        setAlertTitle('Failed');
        setAlertBody("Task Delete Failed!");
        setDialogResStatus('failure');
        setSubmitDelAction(false);
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('delete_failed');
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setDialogResStatus('failure');

     
      setSubmitDelAction(false);
      setShowAlert(true);
    }
  }
  */
  

  const handleDelete = async (e) => {
    console.log("Handle Delete clicked : ", e);
    console.log("Handle Delete clicked current target ID: ", e.currentTarget.id);

    setAlertPurpose('delete');
    //setDialogResStatus('delete');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this Task!");
    setShowAlert(true);

  };


 
  
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };
  
  
  const handleSave = async (e) => {

    e.preventDefault(e);
    console.log("Handle Click save called : ", e);
    console.log("Formvalues at handleSave >>>>>>>>>  : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      //submitTaskvalues();
     
    }
  
  };
  

 
  const handleAlertClose = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listTasks");
    }
  }


  const handleAlertOK = async(e) => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Handle alert close called ...: ", alertPurpose);
    setShowAlert(false);
    setSubmitAction(false);
    
    if( alertPurpose === 'submit') {
      //callApiToGenerateOS();
    }
    else if ( alertPurpose === 'success') {
      navigate('/listTasks');
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'confirmed') {
      //setAlertPurpose('deleted');
      //await submitTaskvalues();
      }
    
   
    else if ( alertPurpose === 'delete' ) {
      //await deleteTask();
    }
    else if ( alertPurpose === 'deleted' ) {
      navigate('/listTasks');
    }
    else if ( alertPurpose === 'delete_failed') {

    } 
  }

  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowAlert(false);
    setSubmitAction(false);
    if ( alertPurpose === 'success') {
      navigate('/listCustOsStmt');
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
  };
 
  
  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
    
 
  
  const handleWorkDateChange = async (e) => {
    console.log("handleWorkDateChange called ...", e);
    console.log("handleWorkDateChange DATE called ...", e.target.value);
    let d = new Date();
    var selectedDate = new Date(e.target.value);
    d.setDate(d.getDate() - 1);
    //d.setDate(selectedDate.getDate() - 1);
    console.log("Current Day before :", d.toISOString().split('T')[0]);
    console.log("SELECTED DAY :", selectedDate.toISOString().split('T')[0]);
    if ( ( (formatDate(selectedDate) === formatDate(d)) || (formatDate(selectedDate) === formatDate(new Date())) ) ) {
      console.log("SELECTED DATE a day before today");
      setFormValues({ ...formValues, work_date: e.target.value });
      setFormErrors({ ...formErrors, date_selection_error: ""});
    }
    else {
      //setFormValues({ ...formValues, work_date: e.target.value });
      //setFormErrors({ ...formErrors, "date_selection_error": "Please select Valid date ..."});
      setFormErrors({ ...formErrors, date_selection_error: "Please select Valid date ..."});
      //alert("SELECTED DATE NOT a day before today");
    }
  };

  const handleActivitySelect = (e, data) => {
    console.log("handleActivitySelect called ...", e);
    setActivityIdSelect({value: e.value, label: e.label});
  };


 
 

  return (
    <>
          <Container>
            <SidebarMain />
            { toRender &&
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    Work Log
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      
                      
                      <Badge pill bg="success">
                        <h6>{state.passed_element.task_sno}</h6>
                      </Badge>
                  
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-task-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-task-name`}
                      id={`edit-icon-task-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                     &nbsp;&nbsp;
                    
                      <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                      <BsPencilSquare
                        key={`edit-btn-task-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-task-id`}
                      />
                    </Button>
                     {/*
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                  */}
                  &nbsp;&nbsp;
                      <Button
                        variant="danger"
                        className="btn btn-danger btn-md"
                        onClick={(e) => handleDelete(e)}
                        disabled={submitDelAction ? true: false}
                      >
                        {submitDelAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }

                        <MdDelete
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>

              <p></p>

              <div className="ui-form">
                <hr align="center" />
                <div className="ui-form"></div>

                <Row className="show-grid">
                
                  <Col xs={9} sm={9} md={9} lg={9}>
                   

                <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
               
                <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Work Description</Form.Label>
                  <Form.Control
                    as="textarea" rows={4}
                    //type="text" rows={6}
                    name="work_description"
                    id="work_description"
                    placeholder="Description"
                    value={formValues.work_description}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                </Row>
                <Row>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Output</Form.Label>
                  <Form.Control
                    as="textarea" rows={5}
                    //type="text" rows={6}
                    name="output"
                    id="output"
                    placeholder="Output"
                    value={formValues.output}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                </Row>
                </Col> 
                </Row>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                 <Row> 
                 <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="work_date"
                    name="work_date"
                    //placeholder="Due Date"
                    value={formValues.work_date}
                    onChange={handleWorkDateChange}
                    //readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.date_selection_error}</p>
                    </Row> 
                    <Row>
                    <Form.Group className="mb-3">
                      <Form.Label style={{paddingBottom:"5px"}}>Activity</Form.Label>
                      <Select
                      inline
                      id="activity"
                      name="activity"
                      value={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      options={optActivity}
                      defaultValue={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      onChange={handleActivitySelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Row>
                    <Row>
                    <Form.Label className="field required">Time Spent</Form.Label>
                  
                 <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="hour_spent"
                    id="hour_spent"
                    placeholder="HH"
                    value={formValues.hour_spent}
                    maxLength={2}
                    onChange={handleValueChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="minute_spent"
                    id="minute_spent"
                    placeholder="MM"
                    value={formValues.minute_spent}
                    maxLength={2}
                    onChange={handleValueChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>
                </Row>
             
                    </Row>
                    <p></p>
                    <Row>
                    <Form.Label>Time Remaining</Form.Label>
                  <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="hour_remaining"
                    id="hour_remaining"
                    placeholder="HH"
                    value={formValues.hour_remaining}
                    maxLength={2}
                    onChange={handleValueChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>
                 &nbsp;&nbsp;
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="minute_remaining"
                    id="minute_remaining"
                    placeholder="MM"
                    value={formValues.minute_remaining}
                    maxLength={2}
                    onChange={handleValueChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>
                </Row>
                    </Row>
                    
                </Col>

                </Row>

                

       
                  
               
                {!isReadOnly && (
                <Row>
                  <Col xs={12} md={12} sm={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-secondary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      &nbsp;&nbsp;
                    

                  
                  &nbsp;&nbsp;
                  
                  <Button className="btn btn-primary" 
                    onClick={(e) => handleSave(e, 'save-only')}
                    //disabled={submitDisabled}
                    //disabled={submitAction ? true: false}
                    >
                    { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }  
                  Save
                  </Button>
                    </div>
                  </Col>
                </Row>
                )}
              
              </div>
            </Form>
           }  
          </Container>
       
      
      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { (alertPurpose == 'submit' || alertPurpose === 'confirmation' || alertPurpose === 'delete' || alertPurpose === 'confirmed') &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateMyWorkLog;