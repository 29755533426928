import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  import { formatDateIndian } from "./utils";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill,
    BsFillFileEarmarkTextFill,
    BsStickies
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import logo_avinaerp from '../assets/images/logo_avinaerp.png';
  
  
  function ListMyTasks() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
    /*
    let taskArr = [
      {
       task_no: "TK001",
       date: "12-09-2023",
       project: "Readymix",
       task_name: "UI Design",
       priority: "Image added",
       status: "completed",
       assigned: "Kavi",
       due_date: "12-12-2023"
      },
     
    ];
   
    let placeArr = [
      {"place": "avn"},
      {"place": "Coimbatore"},
      {"place": "Tiruppur"},
      {"place": "avn"},
      {"place": "Coimbatore"}, 
      {"place": "Sevur"}
      ];
    const unique = [...new Set(placeArr.map(item => item.place))];
    console.log(unique);
    */
  
    const [checked, setChecked] = useState(false);
  
    const [taskList, setTaskList] = useState([]);
    const [customerListFull, setCustomerListFull] = useState([]);
    const [toRender, setToRender] = useState(false);
    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState(
      "Invalid authentication Session!"
    );
  
    const [selectedId, setSelectedId] = useState(-1);
  
    const [showDialog, setShowDialog] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [projectIdSelect, setProjectIdSelect] = useState({value: 0, label: 'ALL'});
    const [optProject, setOptProject] = useState([]);
    const [priorityIdSelect, setPriorityIdSelect] = useState({value: 0, label: 'ALL'});
    const [optPriority, setOptPriority] = useState([]);
    const [statusIdSelect, setStatusIdSelect] = useState({value: 0, label: 'ALL'});
    const [optStatus, setOptStatus] = useState([]);
    //const [assignToIdSelect, setAssignToIdSelect] = useState({});
    //const [optAssignTo, setOptAssignTo] = useState([]);
  
    const handleCloseDialog = () => {
      setShowDialog(false);
    };
  
    const handleShowDialog = () => {
      setShowDialog(true);
    };
    
   
    const actionEditHandle = (e, data) => {
      console.log("Handle action edit Clicked : ", e);
      //console.log("Handle Clicked edit DATA : ", data);
      console.log("Handle action edit Clicked target : ", e.target);
      console.log(
        "Handle action edit Clicked target Current : ",
        e.currentTarget
      );
      console.log(
        "Handle action edit Clicked target Current id : ",
        e.currentTarget.id
      );
      console.log("Handle action edit Clicked target ID : ", e.target.id);
      console.log("Handle action edit Clicked target NAME : ", e.target.name);
    };
    
    const getMyTaskList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/pmo/getMyTasks",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setTaskList(res.data.detail.data);
          //setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Task Info Added Successfully");
          setToRender(true);
          //navigate("/dashboard");
          //setDcReportData(res.data.detail.res_data);
          //setToRender(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    
    {
      /*
    const handleAddAction = () => {
      console.log("user confirmed to added or not ...");
      console.log("user selected id for action is : ", selectedId);
  
      // call update API
      updateCustomerStatus(selectedId);
    };
  */
    }
  
    const handleStateChange = (e) => {
      console.log("clicked Value :", e);
      const { name, value } = e.target;
      console.log("Captured input element name : ", name);
      console.log("Captured input element value : ", value);
  
      var currentId = name.substring(name.lastIndexOf("-") + 1);
      var nameOfElement = name.substring(0, name.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current id : ", currentId);
    };
   
  
    const handleChange = (nextChecked) => {
      setChecked(nextChecked);
    };
    
  
    const handleProjectSelect = (e, data) => {
      console.log("handleProjectSelect called ...", e);
      setProjectIdSelect(e);
    };
    const handlePrioritySelect = (e, data) => {
      console.log("hadlePrioritySelect called ...", e);
     setPriorityIdSelect(e);
    };

    const handleStatusSelect = (e, data) => {
      console.log("handleStatusSelect called ...", e);
      setStatusIdSelect(e);
    };

   
  
    useEffect(() => {
      if (!initialized.current) {
        console.log(
          "*************** useEffect::ListMyTasks called ##########################"
        );
       getMyTaskList();
        initialized.current = true;

        (async() => {
          await getStaticDataSearchTask();
        })();
        
        
      }
    }, []);
  
  
    const handleView = async (e) => {
      console.log("handle edit customers called : ", e);
      console.log(
        "handle edit  currentTarget called : ",
        e.currentTarget
      );
      console.log("handle edit  target called : ", e.target);
      var selectedTagId = e.currentTarget.id;
      console.log("SELECTED TAG ID: ", selectedTagId);
      if (selectedTagId) {
        var currentId = selectedTagId.substring(
          selectedTagId.lastIndexOf("-") + 1
        );
        var nameOfElement = selectedTagId.substring(
          0,
          selectedTagId.lastIndexOf("-")
        );
        console.log("Name of element VIEW: ", nameOfElement);
        console.log("Current id VIEW : ", currentId);
        
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
          taskList[currentId]
        );
        var pass_data = { form_values: taskList[currentId], full_response: {}};
        navigate("/addUpdateTaskProgress", {
          state: {
            passed_element: pass_data,
            action: "view",
            origin: 'listMyTasks'
          },
        });
        
      }
    };
  
    const handleOnChange = async (e) => {
      console.log("handle on change called : ", e);
  
      var selectedTagId = e.target.id;
      console.log("SELECTED TAG ID: ", selectedTagId);
      var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element : ", nameOfElement);
      console.log("Current id : ", currentId);
  
      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
        taskList[currentId]
      );
  
      setSelectedId(currentId);
  
      setShowDialog(true);
    };
  
    const handleAlertClose = async (e) => {
      setShow(false);
      navigate(-1);
    };

    const getStaticDataSearchTask = async() => {
      var dataSearchArr = [];
       var retState = false;
       try {
         var aIVal = localStorage.getItem("_aI");
         var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
         var res = await axios.get(client.domain + "/pmo/getStaticDataSearchTask", { headers }, { withCredentials: false });
         console.log("Response from server getStaticDataSearchTask : ", res.data.detail);
   
         if (res.data.detail.status === "SUCCESS") {
           console.log("Response message from server getStaticDataSearchTask : ", res.data.detail.data);
   
   
            console.log("<<<<<<<<<<<<<Before set console list>>>>>>>>>>>>>>>>>>");
            setOptProject(res.data.detail.data.projects);
         
            console.log("<<<<<<<<<<<<<After set console list>>>>>>>>>>>>>>>>>>")
            var findDefault = res.data.detail.data.projects.find(element => element.is_default === true);
            if (findDefault !== undefined) {
              setProjectIdSelect(findDefault);  
            }
            
           console.log("<<<<<<<<<<<<<Before Priority set console list>>>>>>>>>>>>>>>>>>");
           setOptPriority(res.data.detail.data.priority);
           console.log("<<<<<<<<<<<<<Before Priority set console list>>>>>>>>>>>>>>>>>>");
           var findDefault = res.data.detail.data.priority.find(element => element.is_default === true);
           if (findDefault !== undefined) {
             setPriorityIdSelect(findDefault);  
           };
           var findDefault = res.data.detail.data.projects.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setProjectIdSelect(findDefault);  
          }
           
          setOptStatus(res.data.detail.data.status);
          var findDefault = res.data.detail.data.status.find(element => element.is_default === true);
          if (findDefault !== undefined) {
            setStatusIdSelect(findDefault);  
          };
          
   
           setToRender(true);
   
           retState = true;
         }
       } catch (error) {
         console.log("FULL ERROR : ", error);
         if (error.code === "ERR_NETWORK") {
           console.log("Not able to reach target server please try after sometime");
   
         } else {
           console.log("ERROR : ", error.response.data.detail);
           if (error.response.data.detail.status === "FAILED") {
             console.log("Request failed due to ", error.response.data.detail.data);
   
           }
         }
       }
       return [retState, dataSearchArr]
    };
   
    const handleReset = async (e) => {
      console.log("handle reset clicked : ", e);
      navigate(0);
      //setOptCustomer(customerListFull);
    };
   /*
    const handleTaskPrint = async (e) => {
     //navigate("/printCustomer")
      
      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(
        urlStr.lastIndexOf(client.urlDelimiter) + 1
      );
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
  
      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);
  
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printTask?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      
      
    };
    */

    const handleTaskSearch = async (e) => {
      console.log("handleTaskSearch called : ", e);
      //await getReceiptList(customerIdSelect.value, formValues.start_date, formValues.end_date);
  };

   
    return (
      <Container>
        <SidebarMain />
        { toRender ?  
              <form>
       
            <Row>
                <Col xs={9} md={9} sm={9} lg={9}> 
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}> My Works</span>
             
               </Col>
               {/*
               <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
              <Button id="taskPrint" name = "taskPrint"  onClick={(e)  => handleTaskPrint(e)}>
                  <BsFillPrinterFill
                   size={18}
                   style={{ color: "white", cursor: "pointer" }}
                   name={`print-task-icon`}
                   id={`print-task-icon`}
                   />
                   </Button>
                   &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="warning"
                  id="add-btn-id"
                  name="add-btn-name"
                  className="justify-content-end float-end"
                  onClick={(e) => handleAdd(e)}
                >
                  New Tasks
                </Button>
              </Col>
             */}
               </Row>
               <hr align="center" />
               <Row>
              
               <Col xs={3} md={3} sm={3} lg={3}>
               <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                <Select
                      inline
                      id="project"
                      name="project"
                      value={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                        //is_default:true
                      }}
                      options={optProject}
                      defaultValue={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                      }}
                      onChange={handleProjectSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                  <Form.Label>Priority</Form.Label>
                     <Select
                      inline
                      id="priority"
                      name="priority"
                      value={{
                        value: priorityIdSelect.value,
                        label: priorityIdSelect.label,
                      }}
                      options={optPriority}
                      defaultValue={{
                        value: priorityIdSelect.value,
                        label: priorityIdSelect.label,
                      }}
                      onChange={handlePrioritySelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                   <Select
                      inline
                      id="status"
                      name="status"
                      value={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      options={optStatus}
                      defaultValue={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      onChange={handleStatusSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>
                    {/*
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                  <Form.Label>Assigned To</Form.Label>
                    <Select
                      inline
                      id="assign_to"
                      name="ssign_to"
                      value={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      options={optAssignTo}
                      defaultValue={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      onChange={handleAssignSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>
                    */}
                    <Col xs={1} md={1} sm={1} lg={1} style={{ paddingTop: "30px" }} >
                      <Button id="search-task-id"
                      size={"md"}
                      variant="success"
                      onClick={(e) => handleTaskSearch(e)}>
                      Search
                      </Button>
                    
                      </Col> 
           
            </Row>
            <hr align="center" />
            <div className="ui-divider"></div>
  
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Task No</th>
                  
                  <th className="table-row-heading" style={{textAlign: "center"}}>Project</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Task</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Priority</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Status</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Assigned</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Due Date</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Progress</th>
                </tr>
              </thead>
              <tbody>
                {taskList.map((task, idx) => (
                  <tr key={`mytask-${idx}`}>
                    <td style={{textAlign: "right"}}>{idx + 1}</td>
                    <td style={{textAlign: "right"}}>{task.task_sno}</td>
                    <td style={{textAlign: "left"}}>{task.project_name}</td>
                    <td style={{textAlign: "left"}}>{task.task_summary}</td>
                    <td style={{textAlign: "left",color: task.priority_cc}}>{task.priority_desc}</td>
                    <td style={{textAlign: "left",color: task.status_cc}}>{task.status_desc}</td>
                    <td style={{textAlign: "left"}}>{task.assigned_to_name}</td>
                    <td style={{textAlign: "left"}}>{formatDateIndian(new Date(task.due_date))}</td>
                    
                    
                    <td style={{textAlign: "center" }}>
                      <BsStickies
                        key={`edit-btn-task-key-${idx}`}
                        size={28}
                        style={{ color: "#0D6EFD", cursor: "pointer" }}
                        onClick={(e) => handleView(e)}
                        name={`edit-icon-task-name-${idx}`}
                        id={`edit-icon-task-id-${idx}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        {/*
        <Modal
          show={showDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Activae / In-Activate this customer? Click OK to proceed...
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSwitchAction}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
                */}
                  </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
       }   
        
       </Container>
     
    );
  }
  export default ListMyTasks;
  