//import "bootstrap/dist/css/bootstrap.min.css"
import React, { Fragment, useState } from 'react';

import { client } from '../config/Config';
import axios from 'axios';

import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
import {Nav, NavItem, Navbar, NavDropdown, Dropdown} from 'react-bootstrap';
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';

//import "bootstrap/dist/css/bootstrap.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css"

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import logo_avs from '../assets/images/logo_avs.png';
import avinaoffice_logo from '../avinaoffice_logo.png';

import { COMPANY_NAME } from '../config/Config';

function Auth() {

  const [justifyActive, setJustifyActive] = useState('tab1');;
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');
  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);

    
    let reqLoginPayload = {
      username : username.value, 
      password : password.value
    };

    function base64ToBytes(base64) {
      const binString = atob(base64);
      return Uint8Array.from(binString, (m) => m.codePointAt(0));
    }
    
    function bytesToBase64(bytes) {
      const binString = String.fromCodePoint(...bytes);
      return btoa(binString);
    }

    var loginData = {
      "apikey":"AOAVN123",
      "uname":username.value,
      "upass":password.value
    };

    let bytesArr = new TextEncoder().encode(JSON.stringify(loginData));
    console.log("BYTE ARRAY : ", bytesArr);
    let loginEncode64 = bytesToBase64(bytesArr);
  
      var headers = client.headers;
      var headers = { ...client.headers, Authorization: `Basic ${loginEncode64}`, licensekey: 'AOLKEY123' }
      //var headers = {...client.headers, Authorization: `Basic eyJBUElLRVkiOiJBVklOQUVSUEtNQzAxIiwiVU5BTUUiOiI4ODcwODg2OTU4IiwiVVBBU1MiOiJ0ZXN0In0=`}
      //var headers = { ...client.headers,Authorization: `Basic eyJBUElLRVkiOiJBVklOQUVSUEtNQzAxIiwiVU5BTUUiOiI4ODcwODg2OTU4IiwiVVBBU1MiOiJ0ZXN0In0=`}
      console.log("payload:", reqLoginPayload);

      try {
      var res = await axios.post(client.domain + '/core/login', reqLoginPayload, { headers }, { withCredentials: false });
      //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server : ", res.data.detail);
          if ( res.data.detail.status === 'SUCCESS') {
            console.log("Response message from server : ", res.data.detail.res_data);
            console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
            console.log("Response message from server aI : ", res.data.detail.res_data.aI);
            console.log("Response message from server PERMISSIONS : ", res.data.detail.res_data.user_info.usr_perm.permissions);


            localStorage.setItem('_aP', JSON.stringify(res.data.detail.res_data.user_info.usr_perm));

            localStorage.setItem('_aI', res.data.detail.res_data.aT);
            //localStorage.setItem('userRoleId', userRoleId);
            localStorage.setItem('dis_name', res.data.detail.res_data.user_info.dis_name);
            //navigate(res.data.detail.res_data.user_info.usr_perm.landingpage)
           
            navigate(`/${res.data.detail.res_data.user_info.usr_perm.landingpage}`);
          }
          /*
          if (res.data.status === "SUCCESS" ) {
            this.setState ({
            tokenJwt: res.data.res_data.token,
            isLoaded: true,
            openOTP: true,
            })
              // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
          }
          else {
          }
        */
        }
        catch(error) {
          console.log("FULL ERROR : ", error);
          if ( error.code === 'ERR_NETWORK' ) {
            console.log("Not able to reach target server please try after sometime");
          }
          else{
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === 'FAILED') {
              console.log('Request failed due to ', error.response.data.detail.res_data);
	            setShowMsg(error.response.data.detail.res_data);
              setShowMsgTitle('LOGIN FAILED!');
	            setShow(true);
            }
          }
        }
        
  }
  
  

  return (
    <Fragment>
      <div style={{marginTop: 150, alignItems: 'center', textAlign: 'center'}}>

    <div>
      <img src={avinaoffice_logo} height="10%" width="10%" alt="Avinasoft"/>
      <label style={{fontFamily: 'Helvetica-Bold', textAlign: 'center', fontWeight: 'bold', fontSize: "20px"}}>{COMPANY_NAME}</label>
    </div>
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="text"
              name="username"
              className="form-control mt-1"
              placeholder="Enter username"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          {/*
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>
  */}
        </div>

   { show === true && 
    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{showMsgTitle}</Alert.Heading>
        <p>
        {showMsg}
        </p>
      </Alert>
   }
   </form> 
   </div>
   </div>
   </Fragment>
  );
}

export default Auth;
