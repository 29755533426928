import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal} from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import InputGroup from 'react-bootstrap/InputGroup';

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsCardText,
  BsFillFileEarmarkTextFill,
  BsFillCloudRainHeavyFill,
  BsStickies
} from "react-icons/bs";
import { MdDelete } from "react-icons/md";

import SidebarMain from "./SidebarMain";
import { formatDateIndian } from "./utils";

const AddUpdateTask = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
 

  let initialItems = [];
 
 
  const [items, setItems] = useState(initialItems);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    due_date: formatDate(new Date()),
    task_summary: "",
    task_description: "",
    expected_output: ""
  };



  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState();
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState();
 
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
 
  const [toRender, setToRender] = useState(false);

 
  const [editAction, setEditAction] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);


  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  const [submitDelAction, setSubmitDelAction] = useState(false);
  const [dialogResStatus, setDialogResStatus] = useState("");
  const [showMsgTitle, setShowMsgTitle] = useState("");
  const [showMsgBody, setShowMsgBody] = useState("");
  const [projectIdSelect, setProjectIdSelect] = useState({});
  const [optProject, setOptProject] = useState([]);
  const [priorityIdSelect, setPriorityIdSelect] = useState({});
  const [optPriority, setOptPriority] = useState([]);
  const [statusIdSelect, setStatusIdSelect] = useState({});
  const [optStatus, setOptStatus] = useState([]);
  const [assignToIdSelect, setAssignToIdSelect] = useState({});
  const [optAssignTo, setOptAssignTo] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const [fullResponse, setFullResponse] = useState({});

 

  const [actionVal, setActionVal] = useState('save-only');

  var validateErr = false;


  
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);
   /*
    if (name === "customer") {
      console.log("Inside check ******************");
      setFormValues({ ...formValues, [name]: value });
    }
    */
   setFormValues({ ...formValues, [name]: value});
  };

 
  

 const getStaticDataCreateTask = async() => {
   var dataCreateArr = [];
    var retState = false;
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/pmo/getStaticDataCreateTask", { headers }, { withCredentials: false });
      console.log("Response from server getStaticDataCreateTask : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getStaticDataCreateTask : ", res.data.detail.data);
        setFullResponse(res.data.detail.data);

        /*
        res.data.detail.data.map((data) => {
          var dataInd = { id: data, label: data };
          dataCreateArr.push(dataInd);
        })
        */
        
        setOptProject(res.data.detail.data.projects);
        if ( state.action === 'view' || state.action === 'edit') {
          var findProject = res.data.detail.data.projects.find(element => element.value === state.passed_element.project_id);
            if (findProject !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND  PROJECT : ", findProject);
              setProjectIdSelect(findProject);
            }
            else {
              setProjectIdSelect(res.data.detail.data.projects[0]);    
            }
        }
        else {
          setProjectIdSelect(res.data.detail.data.projects[0]);
        }

        setOptPriority(res.data.detail.data.priority);
        if (state.action === 'view'|| state.action === 'edit') {
          var findPriority = res.data.detail.data.priority.find(element => element.value === state.passed_element.priority_code);
          if (findPriority !== undefined) {
            console.log("++++++++++++++++++------------------- FOUND PRIORITY : ", findProject);
            setPriorityIdSelect(findPriority);
          }
          else {
            setPriorityIdSelect(res.data.detail.data.priority[0]);    
          } 
        }
       else {
        setPriorityIdSelect(res.data.detail.data.priority[0]);
       }

       
       setOptStatus(res.data.detail.data.status);
       if (state.action === 'view' || state.action === 'edit') {
        var findStatus = res.data.detail.data.status.find(element => element.value === state.passed_element.status_code);
        if (findStatus !== undefined) {
          console.log("++++++++++++++++++------------------- FOUND STATUS : ", findStatus);
            setStatusIdSelect(findStatus);
        }
        else {
          setStatusIdSelect(res.data.detail.data.status[0]);
        }
       }
       else{
        setStatusIdSelect(res.data.detail.data.status[0]);
       }

       setOptAssignTo(res.data.detail.data.assignedto);
       if (state.action === 'view' || state.action === 'edit') {
        var findAssignedto = res.data.detail.data.assignedto.find(element => element.value === state.passed_element.assigned_to_id);
        if (findAssignedto !== undefined) {
          console.log("++++++++++++++++++------------------- FOUND ASSIGNED TO : ", findAssignedto);
            setAssignToIdSelect(findAssignedto);
        }
        else {
          setAssignToIdSelect(res.data.detail.data.assignedto[0]);
        }
       }
       else{
          setAssignToIdSelect(res.data.detail.data.assignedto[0]);
       }
      

       
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.data);

        }
      }
    }
    return [retState, dataCreateArr]
 };
 

  useEffect(() => {
    if (!initialized.current) {
        console.log(
          "*************** useEffect::addUpdateTask called ##########################"
        );
  
        if (state === null) {
          navigate("/listTasks");
        } else {
          console.log("PASSED STATE VALUE IN TASK Add/Update page : ", state);
           console.log("Inside else for view or edit ...");
              //setFormValues(state.passed_element);
          if (state.action === "add") {
            setIsReadOnly(true);
          }
          else {
            setFormValues(state.passed_element);
          }
          console.log("newObj :", formValues);
          if (state.action === "add") {
            setIsReadOnly(false);
          }
          setToRender(true);
        }
        
        (async() => {
          await getStaticDataCreateTask();
        })();

        initialized.current = true;
      }
   
  }, []);


  const submitTaskvalues = async(e) => {
    console.log("submitTaskvalues called : ", e);
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/pmo/createTask";
      } else if (state.action === "view") {
        apiToCall = "/pmo/updateTask";
      } else {
        apiToCall = "/pmo/createTask";
      }

      var reqParam = {
        task_id: formValues["id"],
        task_summary: formValues["task_summary"],
        project_id: projectIdSelect.value,
        task_desc: formValues["task_description"] || "",
        expected_output: formValues["expected_output"] || "",
        assigned_to: assignToIdSelect.value,
        due_date: formValues['due_date'],
        priority_code: priorityIdSelect.value,
        status_code: statusIdSelect.value
      };
      
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setAlertPurpose('success');
        setAlertTitle('Success');
       
       
          setAlertBody(res.data.detail.data.message);
           /*
           if(state.action === "add"){
        }
        else{
          setAlertBody("Task Updated Successfully!");
        }
        //setAlertBody("Summary Updated Successfully!");
        */
        setSubmitAction(false);
        setShowAlert(true);
       
      }
      else {
          setAlertPurpose('failure');
          setAlertTitle('Failed');
          if(state.action === "add"){
            setAlertBody("Task Save Failed!");
          }
          else{
            setAlertBody("Task Update Failed!");
          }
          //setAlertBody("Summary Update Failed!");
          setSubmitAction(false);
          setShowAlert(true);
          
        }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('failure');
      setAlertTitle('Failed');
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setSubmitAction(false);
      setShowAlert(true);
    }


  }
  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
  
    console.log("Form submit called ...");

    console.log("FORM VALUES at handleChange func : ", formValues);

    console.log("CUSTOMER : ", formValues["customer_name"]);
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }

    submitTaskvalues();
    
  };
  */
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
      
    if (!values.task_summary){ 
      errors.task_summary = "Summary is required!";
      validateErr = true;
    }
    else {
      errors.task_summary = "";
    }


   
    return errors;
  };



 

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
 
  
  const deleteTask = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      
      let reqAddUpdateTask = {
        task_id: state.passed_element.id,
        
      };
      setSubmitDelAction(true);
      var res = await axios.post(client.domain + "/pmo/deleteTask", reqAddUpdateTask, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setAlertPurpose('deleted');
        setAlertTitle('Success');
        setAlertBody(res.data.detail.data.message);
        setDialogResStatus('success');
        setSubmitDelAction(false);
        setShowAlert(true);
        }
      else {
        setAlertPurpose('delete_failed');
        setAlertTitle('Failed');
        setAlertBody("Task Delete Failed!");
        setDialogResStatus('failure');
        setSubmitDelAction(false);
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('delete_failed');
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setDialogResStatus('failure');

     
      setSubmitDelAction(false);
      setShowAlert(true);
    }
  }
  

  const handleDelete = async (e) => {
    console.log("Handle Delete clicked : ", e);
    console.log("Handle Delete clicked current target ID: ", e.currentTarget.id);

    setAlertPurpose('delete');
    //setDialogResStatus('delete');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this Task!");
    setShowAlert(true);

    //setIsReadOnly(false);

    //await deleteTask();
  };

 
  
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };
  
  
  const handleSave = async (e) => {

    e.preventDefault(e);
    console.log("Handle Click save called : ", e);
    console.log("Formvalues at handleSave >>>>>>>>>  : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      submitTaskvalues();
     
    }
  
  };
  

 
  const handleAlertClose = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listTasks");
    }
  }
 
  const handleAlertOK = async(e) => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Handle alert close called ...: ", alertPurpose);
    setShowAlert(false);
    setSubmitAction(false);
    
    if( alertPurpose === 'submit') {
      //callApiToGenerateOS();
    }
    else if ( alertPurpose === 'success') {
      navigate('/listTasks');
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'confirmed') {
      //setAlertPurpose('deleted');
      await submitTaskvalues();
      }
    
   
    else if ( alertPurpose === 'delete' ) {
      await deleteTask();
    }
    else if ( alertPurpose === 'deleted' ) {
      navigate('/listTasks');
    }
    else if ( alertPurpose === 'delete_failed') {

    } 
  }

  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowAlert(false);
    setSubmitAction(false);
    if ( alertPurpose === 'success') {
      navigate('/listTasks');
      //setAlertPurpose('init');
    }
    else  {
      //setAlertPurpose('init');
    }
  };
 
  const handleView = async (e) => {
    console.log("handle view  clicked : ", e);
     
    console.log("handle edit  called : ", e);
      console.log("handle edit customers currentTarget called : ", e.currentTarget);
      console.log("handle edit  target called : ", e.target);
      /*
      console.log("<*<*<*<*<*<*<*<*<*<*<*<*<*<*<*<*<*< TASK LIST : ", taskList);
      var selectedTagId = e.currentTarget.id;
      console.log("SELECTED TAG ID: ", selectedTagId);
      if (selectedTagId) {
        var currentId = selectedTagId.substring(
          selectedTagId.lastIndexOf("-") + 1
        );
        var nameOfElement = selectedTagId.substring(
          0,
          selectedTagId.lastIndexOf("-")
        );
        console.log("Name of element VIEW: ", nameOfElement);
        console.log("Current id VIEW : ", currentId);
        
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
          taskList[currentId]
        );
       
      
      var pass_data = {
        form_values: formValues, 
        full_response: fullResponse 
      };
      navigate("/addUpdateTaskProgress", {
        state: {
          passed_element: pass_data,
          action: "view",
        },
      });
    }
    */
    var pass_data = {
      form_values: formValues, 
      full_response: fullResponse 
    };
    navigate("/addUpdateTaskProgress", {
      state: {
        passed_element: pass_data,
        action: "view",
        origin: 'addUpdateTask'
      },
    });

   {/*
    navigate("/addUpdateTaskProgress", {
      state: {
        passed_element: taskList[currentId],
        action: "view",
      },
    });
  */}
   // navigate("/addUpdateTaskProgress")
    //navigate(-1);
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
    
  const handleProjectSelect = (e, data) => {
    console.log("handleProjectSelect called ...", e);
    setProjectIdSelect({value: e.value, label: e.label}); 
  };
  const handlePrioritySelect = (e, data) => {
    console.log("hadlePrioritySelect called ...", e);
    setPriorityIdSelect({value: e.value, label: e.label});
  };

  const handleStatusSelect = (e, data) => {
    console.log("handleStatusSelect called ...", e);
    setStatusIdSelect({value: e.value, label: e.label});
  };

  const handleAssignSelect = (e, data) => {
    console.log("handleAssignSelect called ...", e);
    setAssignToIdSelect({value: e.value, label: e.label});
  };

  const handleDueDateChange = async (e) => {
    console.log("handleReceiptDateChange called ...", e);
    console.log("handleReceiptDateChange DATE called ...", e.target.value);

    var currDate = new Date();
    currDate.setHours(0,0,0,0);
    var selectedDate = new Date(e.target.value);

    if ( selectedDate >= currDate ) {
      console.log("Date selected:", selectedDate);
      setFormValues({ ...formValues, due_date: e.target.value });
      setFormErrors({ ...formErrors, "date_selection_error": ""});
    }
    else {
      setFormValues({ ...formValues, due_date: e.target.value });
      setFormErrors({ ...formErrors, "date_selection_error": "Please select future date ..."});
    }
  };


 
 

  return (
    <>
          <Container>
            <SidebarMain />
            { toRender ?
        (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    Task
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      
                      
                      <Badge pill bg="success">
                        <h6>{state.passed_element.task_sno}</h6>
                      </Badge>
                  
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-task-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-task-name`}
                      id={`edit-icon-task-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                     &nbsp;&nbsp;
                    <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleView(e)}>
                      <BsStickies
                        key={`view-btn-task-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`view-icon-paBiSolidEditAlttient-name`}
                        id={`view-icon-task-id`}
                      />
                    </Button>
                      
                      &nbsp;&nbsp;

                      <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                      <BsPencilSquare
                        key={`edit-btn-task-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-task-id`}
                      />
                    </Button>
                     {/*
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                  */}
                  &nbsp;&nbsp;
                      <Button
                        variant="danger"
                        className="btn btn-danger btn-md"
                        onClick={(e) => handleDelete(e)}
                        disabled={submitDelAction ? true: false}
                      >
                        {submitDelAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }

                        <MdDelete
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>

              <p></p>

              <div className="ui-form">
                <hr align="center" />
                <div className="ui-form"></div>

                <Row className="show-grid">
                
                  <Col xs={9} sm={9} md={9} lg={9}>
                   

                <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
               
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Summary </Form.Label>
                  <Form.Control
                    type="text"
                    name="task_summary"
                    id="task_summary"
                    placeholder="Summary"
                    value={formValues.task_summary}
                    onChange={handleValueChange}
                     readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.task_summary}</p>
            

                <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea" rows={4}
                    //type="text" rows={6}
                    name="task_description"
                    id="task_description"
                    placeholder="Description"
                    value={formValues.task_description}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                </Row>
                <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Expected Output</Form.Label>
                  <Form.Control
                    as="textarea" rows={5}
                    //type="text" rows={6}
                    name="expected_output"
                    id="expected_output"
                    placeholder="Expected Output"
                    value={formValues.expected_output}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                </Row>
                </Col> 
                </Row>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                 <Row>  
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                <Select
                      inline
                      id="project"
                      name="project"
                      value={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                      }}
                      options={optProject}
                      defaultValue={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                      }}
                      onChange={handleProjectSelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Row> 
                    <Row>
                    <Form.Group className="mb-3">
                  <Form.Label>Priority</Form.Label>
                     <Select
                      inline
                      id="priority"
                      name="priority"
                      value={{
                        value: priorityIdSelect.value,
                        label: priorityIdSelect.label,
                      }}
                      options={optPriority}
                      defaultValue={{
                        value: priorityIdSelect.value,
                        label: priorityIdSelect.label,
                      }}
                      onChange={handlePrioritySelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group> 
                    </Row>
                    <Row>
                    <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                   <Select
                      inline
                      id="status"
                      name="status"
                      value={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      options={optStatus}
                      defaultValue={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      onChange={handleStatusSelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group> 
                    </Row>
                    <Row>
                    <Form.Group className="mb-3">
                  <Form.Label>Assigned To</Form.Label>
                    <Select
                      inline
                      id="assign_to"
                      name="ssign_to"
                      value={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      options={optAssignTo}
                      defaultValue={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      onChange={handleAssignSelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group> 
                    </Row>
                    <Row>

             
                  <Form.Group className="mb-3">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="due_date"
                    name="due_date"
                    placeholder="Due Date"
                    value={formValues.due_date}
                    onChange={handleDueDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.date_selection_error}</p>
              </Row>
                </Col>

                </Row>

                

       
                  
               
                {!isReadOnly && (
                <Row>
                  <Col xs={12} md={12} sm={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-secondary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      &nbsp;&nbsp;
                    

                  
                  &nbsp;&nbsp;
                  
                  <Button className="btn btn-primary" 
                    onClick={(e) => handleSave(e, 'save-only')}
                    //disabled={submitDisabled}
                    //disabled={submitAction ? true: false}
                    >
                    { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }  
                  Save
                  </Button>
                    </div>
                  </Col>
                </Row>
                )}
              
              </div>
            </Form>
            )
            :
            <div className="container-spinner">
            <div className="vertical-center-spinner">
             {/*<Button variant="primary" disabled>*/}
             <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
       
             {/*</Button>*/}
             </div>
             </div>
          } 
          </Container>
       
      
      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { (alertPurpose == 'submit' || alertPurpose === 'confirmation' || alertPurpose === 'delete' || alertPurpose === 'confirmed') &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateTask;