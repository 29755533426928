import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import avinaoffice_logo from '../avinaoffice_logo.png';
import logo_kmc2 from '../logo_kmc2.png';
//import avina_shop from '../avina_shop.png';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  
  snoTask: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left' 
  },
  noTask: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  /*
  dateTask: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  */

  projectTask: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  task_nameTask: {
    width: '35%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 20  
  },
  priorityTask: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
    paddingLeft: 15  
  },
  statusTask: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center'  
  },
  assignedTask: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center'  
  },
  due_dateTask: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center'  
  },

  snoTaskData: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingRight:30,
    textAlign: 'right' 
  },
  noTaskData: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  /*
  dateTaskData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
   // paddingLeft: 5
  
  },
  */

  projectTaskData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 10   
  },
  task_nameTaskData: {
    width: '35%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: 52  
  },
  priorityTaskData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 34,
    //color: priority_cc 
    //paddingRight:"70px"
  },
  statusTaskData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: "90px"
    paddingLeft: 15,
    //color: status_cc    
  },
  assignedTaskData: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: "25px"  
  },
  due_dateTaskData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
    //paddingLeft: 40  
    //paddingLeft: "130px"
  },
  


});

const PrintTask = (props) => {
    /*
    let taskArr = [
        {
         task_no: "TK001",
         date: "12-09-2023",
         project: "Readymix",
         task_name: "UI Design",
         priority: "Image added",
         status: "completed",
         assigned: "Kavi",
         due_date: "12-12-2023"
        },
       
      ];
      */
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
 
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
 
  const getTaskList = async() => {
    //console.log("getPaymentList method called ...");
    //console.log("start date   : ", start_date);
   // console.log("end date     : ", end_date);
    //console.log("paid to  : ", paid_to);
    //console.log("expense category : ", expense_category_id);
    var retState = false;
    var retTaskList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + `/pmo/getAllTasks?`, {headers}, {
        withCredentials: false,
      });
     
      console.log("Response from server getTaskList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTaskList : ", res.data.detail.data);
       if (res.data.detail.data.length > 0) {
          setTaskList(res.data.detail.data);
          //setReceiptListFull(res.data.detail.data);
          retTaskList = res.data.detail.data;
        }
        retState = true;
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
    setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retTaskList];
  };
  
  
  useEffect(() => {
    console.log("useEffect method called ...");

    (async () => {
      
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
      }
      
      var [retState, retTaskList] = await getTaskList();
      console.log("CALLED CURRENT PAGE : ", window.location.href);

        console.log("CALLED window locaion : ", window.location);
        let params = (new URL(window.location)).searchParams;
        console.log("COMPLETE PARAMS : ", params);
   // let receiptIdParam = params.get("customer_id");
    //console.log("RECEIPT ID FROM PARAM : ", receiptIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    //var paid_to = searchParams.get("paid_to");
    //console.log("^^^^^^^^^^^^^^^^^^^ PAID TO : ",paid_to);
    //paid_to_ref.current = paid_to; 
   // var [retState, retTaskList] = await getTaskList();
    setToRender(true);
    })();
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={avinaoffice_logo} />
                </View>
                <View style={{ width: "50%", alignItems: "center"}} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Avinasoft Technologies</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '100px', alignItems: 'right'}}>
                  <Image style={{  width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={avinaoffice_logo} />
                </View>
              </View>
            </View>
              
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Task List as on {formatDateIndian(new Date())}</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>

            <View style={styles.tableContainer}>
            
            <View style={styles.container}>
                <Text style={styles.snoTask}>S.No</Text>
                <Text style={styles.noTask}>Task No</Text>
                <Text style={styles.projectTask}>Project</Text>
                <Text style={styles.task_nameTask}>Task</Text>
                <Text style={styles.priorityTask}>Priority</Text>
                <Text style={styles.statusTask}>Status</Text>
                <Text style={styles.assignedTask}>Assigned</Text>
                <Text style={styles.due_dateTask}>Due Date</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {taskList.map((task, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoTaskData}>{idx + 1}</Text>
                    <Text style={styles.noTaskData}>{task.task_sno}</Text>
                    <Text style={styles.projectTaskData}>{task.project_name}</Text>
                    <Text style={styles.task_nameTaskData}>{task.task_summary}</Text>
                    <Text style={{...styles.priorityTaskData,color:task.priority_cc}}>{task.priority_desc}</Text>
                    <Text style={styles.statusTaskData}>{task.status_desc}</Text>
                    <Text style={styles.assignedTaskData}>{task.assigned_to_name}</Text>
                    <Text style={styles.due_dateTaskData}>{formatDateIndian(new Date(task.due_date))}</Text>
              </View>
               ))}
              </View>
            
              </View>
             
           
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{  textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintTask;