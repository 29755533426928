import React, { Fragment, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
import {Nav, NavItem, Navbar, NavDropdown, Dropdown, Badge} from 'react-bootstrap';
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select from "react-select";
import styles from '../sideBarMenu.css';

//import { withRouter } from "react-router";
import '../Dashboard.css'

import logo_avs from '../assets/images/logo_avs.png';
import avina_shop from '../assets/images/avina_shop.png';
import avinaoffice_logo from '../avinaoffice_logo.png';

import { useNavigate } from "react-router-dom";

import { client, buildType, COMPANY_NAME } from '../config/Config';
import axios from 'axios';

//import {handleLogoutClick} from './utils';

import {BsFillPersonVcardFill, BsPower} from 'react-icons/bs';
import { IoSettings } from "react-icons/io5";

const SidebarMain = props => {

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  const projectArr = [
    {value: "Avinabooks", label: "avinaBooks"},
    {value: "Avinabookslite", label: "avinabooks Lite"},
    {value: "Avinaerp", label: "avinaErp"}
  ];
  const [projectIdSelect, setProjectIdSelect] = useState(projectArr[0]);
  const [optProject, setOptProject] = useState(projectArr);

  const navigate = useNavigate();

  
  const handleLogoutClick = async() => {
    console.log("Logout button clicked ...");

    var aIFromLocalStorage = localStorage.getItem('_aI');
    var usernameFromLocalStorage = localStorage.getItem('username');
    console.log("username : ", usernameFromLocalStorage);
    console.log("aI : ", aIFromLocalStorage);

    console.log("username : ", usernameFromLocalStorage);
    console.log("jwt token  : ", aIFromLocalStorage);

    
    
 
    
    let reqLogoutPayload = {
      //username : usernameFromLocalStorage, 
      //aI : aIFromLocalStorage
    };
  
      //var bearerToken = `Bearer ${aIFromLocalStorage}`;
      var headers = { ...client.headers, "Authorization": `Bearer ${aIFromLocalStorage}`};
      console.log("payload:", reqLogoutPayload);

      try {
      var res = await axios.post(client.domain + '/core/logout', reqLogoutPayload, { headers }, { withCredentials: false });
      //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server : ", res.data.detail);
          if ( res.data.detail.status === 'SUCCESS') {
            console.log("Response message from server : ", res.data.detail.res_data);
            console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
            //console.log("Response message from server aI : ", res.data.detail.res_data.aI);

            // Remove login session from localstorage
            localStorage.removeItem("username");
            localStorage.removeItem("_aI");
            localStorage.removeItem("_aP");

            navigate("/auth");
          }
          /*
          if (res.data.status === "SUCCESS" ) {
            this.setState ({
            tokenJwt: res.data.res_data.token,
            isLoaded: true,
            openOTP: true,
            })
              // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
          }
          else {
          }
        */
        }
        catch(error) {
          console.log("FULL ERROR : ", error);
          if ( error.code === 'ERR_NETWORK' ) {
            console.log("Not able to reach target server please try after sometime");
          }
          else{
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === 'FAILED') {
              console.log('Request failed due to ', error.response.data.detail.res_data);
	      setShowMsg(error.response.data.detail.res_data);
              setShowMsgTitle('LOGIN FAILED!');
	      setShow(true);
            }
            navigate("/auth");
          }
        }
  }

  const handleProfile = async(e) => {
    console.log("handle profile clicked : ", e);
  }

  const handleProjectSelect = async(e) => {
    console.log("handleProjectSelect called ...", e);

    setProjectIdSelect(e);
  }
  
   
    //const handleSelect = (eventKey) => {
    //    alert(`selected ${eventKey}`);
    //}
    
  var menuSettingsStr = localStorage.getItem("_aP");
  var menuSettings = JSON.parse(menuSettingsStr);

  /*
  menuSettings.map((settiing) =>{

  })
  */
    return (      
      <>        
      
        <Navbar key="sm" expand="sm" bg="secondary" fluid="true" className="bg-body-tertiary mb-3" style={{backgroundColor:"black"}}>
            
          <Container fluid >
            <Navbar.Brand href={client.urlDelimiter}>
            <img src={avinaoffice_logo} height="64" width="64" alt="Avinasoft"/>
            </Navbar.Brand>

            <Navbar.Brand href={client.urlDelimiter}>{COMPANY_NAME}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  {COMPANY_NAME}
                  <span style={{alignItems: 'right', textAlign: 'right', fontSize: '8px !important' }}>Logged In: {localStorage.getItem("dis_name")}</span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body  key={'canvas-1'}>
              <Nav key={"logged-disp-name"} className="justify-content-end flex-grow-1" >
              {/*<Button size="sm" variant="outline-info" id="profile-name" style={{fontSize: '12px', verticalAlign: '-2px', marginTop: '8px'}}>Welcome to {localStorage.getItem("dis_name")}</Button>*/}
              <Form.Label>Logged In:  {localStorage.getItem("dis_name")}</Form.Label>
              </Nav>
              </Offcanvas.Body>
              <Offcanvas.Body key={'canvas-2'}>
                <Nav key={`nav-for-checkin-button-${Math.random()}`}>
                <Button
                  key={`checkin-button-${Math.random()}`}
                  variant="success"
                  size="sm"
                  id="add-btn-id"
                  name="add-btn-name"
                  className="justify-content-start"
                  //onClick={(e) => handleAdd(e)}
                >
                  Check In
                </Button>
                </Nav>
              
              <Nav key={"usermenu"} className="justify-content-end flex-grow-1">
                  {/*<Button size="sm" variant="outline-info" id="profile-name" style={{fontSize: '12px', verticalAlign: '-2px', marginTop: '8px'}}>Check In</Button>*/}
               
                
               
                &nbsp;&nbsp;
               
               {/*
               <div style={{width: '120px',fontSize:"12px"}}>
                <Select
                      inline
                      id="project"
                      name="project"
                      value={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                      }}
                      options={optProject}
                      defaultValue={{
                        value: projectIdSelect.value,
                        label: projectIdSelect.label,
                      }}
                      onChange={handleProjectSelect}
                      size="sm"
                      //style={{customStyles}}
                      //isDisabled={isReadOnly}
                    />
                    </div>
                    */}
                {/*
                <NavDropdown
                    title="Master Data"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  >
                    <NavDropdown.Item href={`${client.urlDelimiter}listCustomers`}>Customers</NavDropdown.Item>
                    { buildType === 'FULL' &&
                    <>
                    <NavDropdown.Item href={`${client.urlDelimiter}listVehicles`}>Vehicles</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listProducts`}>Products</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listSuppliers`}>Suppliers</NavDropdown.Item>
                    {/ *<NavDropdown.Item href={`${client.urlDelimiter}listStocks`}>Opening Stock</NavDropdown.Item>* /}
                    <NavDropdown.Item href={`${client.urlDelimiter}listEmployees`}>Employees</NavDropdown.Item>
                    </>
                    }
                </NavDropdown>

                <NavDropdown
                    title="Transaction"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  >
                    <NavDropdown.Item href={`${client.urlDelimiter}addUpdateDirectDC`}>New DC</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listDCs`}>DC List</NavDropdown.Item>
                    { buildType === 'FULL' &&
                    <>
                    <NavDropdown.Item href={`${client.urlDelimiter}listDeliverySummary`}>Delivery Summary</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listCustOsStmt`}>Outstanding Statement</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listCustInv`}>Invoice</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listCustReceipts`}>Receipts</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listSuppPayments`}>Supplier Payments</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listExpPayments`}>Expense Payments</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listEmpPayments`}>Employee Payments</NavDropdown.Item>
                    {/ *<NavDropdown.Item href={`${client.urlDelimiter}listPurchaseOrders`}>Purchase Orders</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listInvoice`}>Invoice</NavDropdown.Item>* /}
                    </>
                    }
                </NavDropdown>

                { buildType === 'FULL' &&
                <NavDropdown title="Reports" id={`offcanvasNavbarDropdown-expand-sm`}>
                    <NavDropdown.Item href={`${client.urlDelimiter}rptCustOsSummary`}>Customer Ouststanding Summary</NavDropdown.Item>
                    
                </NavDropdown>
                }

                { buildType === 'FULL' &&
                <NavDropdown title="Settings" id={`offcanvasNavbarDropdown-expand-sm`}>
                    <NavDropdown.Item href={`${client.urlDelimiter}listDocNumber`}>Doc Numbers</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}addUpdateCompany`}>Company</NavDropdown.Item>
                    <NavDropdown.Item href={`${client.urlDelimiter}listUsers`}>Users</NavDropdown.Item>
                </NavDropdown>
                }
                
                {/ *
                <NavDropdown
                    title="Profile"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                    
                  >
                 <NavDropdown.Item href={`${client.urlDelimiter}addUpdateProfile`}> My Profile</NavDropdown.Item>
                </NavDropdown>
                * /}
                <Nav.Link href={`${client.urlDelimiter}addUpdateProfile`} onClick={(e)=> handleProfile(e)}><BsFillPersonVcardFill size={28}  style={{cursor: 'pointer', color: 'blue'}} /></Nav.Link>
                
                {/ *
                <NavDropdown 
                  title = {<span><BsFillPersonVcardFill size={28} style={{cursor: 'pointer'}}/></span>}
                  id="navdropdown-profile"
                >
                <NavDropdown.Item onClick={handleProfile}>My Profile</NavDropdown.Item>
                </NavDropdown>
                * /}
                <Nav.Link onClick={()=> handleLogoutClick() }><BsPower size={28} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
                {/ *<Nav.Link>Rayappan</Nav.Link>* /}
              */}
              {/*<Nav.Link href={`${client.urlDelimiter}/addUpdateInvoice`}>New Invoice</Nav.Link>*/}
              {menuSettings["permissions"].map((permissonInd, idx) => (
                <>
                {(permissonInd.code === 'listtasks' && permissonInd.access === true) &&
                <Nav.Link href={`${client.urlDelimiter}/listTasks`}>Tasks</Nav.Link>}
              
                {(permissonInd.code === 'listmytasks' && permissonInd.access === true) &&
                <Nav.Link href={`${client.urlDelimiter}/listMyTasks`}>My Work</Nav.Link>}
                
                </>              
              ))}
              <Nav.Link href={`${client.urlDelimiter}/listMyTimeSheets`}>My TimeSheets</Nav.Link>
              <Nav.Link href={`${client.urlDelimiter}/listMyWorkLogs`}>My WorkLogs</Nav.Link>
              <Nav.Link href={`${client.urlDelimiter}/listBugs`}>Bugs</Nav.Link>
              <Nav.Link href={`${client.urlDelimiter}/listTickets`}>Tickets</Nav.Link>

            <Nav.Link href={`${client.urlDelimiter}/addUpdateBusiness`}><IoSettings size={28} style={{cursor: 'pointer', color: 'blue'}}/></Nav.Link>
            {/*<Nav.Link href={`${client.urlDelimiter}/addUpdateProfile`} onClick={(e)=> handleProfile(e)}><BsFillPersonVcardFill size={28}  style={{cursor: 'pointer', color: 'blue'}} /></Nav.Link>*/}
            <Nav.Link onClick={()=> handleLogoutClick()}><BsPower size={28} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
                </Nav>
                {/*
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
                */}
              
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
        {/*
        <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">AvinaBooks</Navbar.Brand>
        <Navbar.Toggle aria-controls="avinabooks-navbar-nav" />
        <Navbar.Collapse id="avinabooks-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href={`${client.urlDelimiter}newInvoice`}>New Invoice</Nav.Link>
            <Nav.Link href={`${client.urlDelimiter}invoiceList`}>Invoice List</Nav.Link>
            <Nav.Link href={`${client.urlDelimiter}settings`}><IoSettings size={28} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
            <Nav.Link href={`${client.urlDelimiter}addUpdateProfile`} onClick={(e)=> handleProfile(e)}><BsFillPersonVcardFill size={28}  style={{cursor: 'pointer', color: 'blue'}} /></Nav.Link>
            <Nav.Link onClick={()=> handleLogoutClick()}><BsPower size={28} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      */}
    </>
        );
  };
  //const Sidebar = withRouter(Side);
  export default SidebarMain