import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View, Modal, Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import SidebarMain from "./SidebarMain";

import { formateTime, getDistrictList, getStateList } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdateBusiness = (props) => {
  const initialized = useRef(false);
  //const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);



  let initialItems = [];

  
/*
  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];
  */
  

  const intialValues = {
    name: "",
    display_name: "",
    gst_no: "",
    email: "",
    contact_person: "",
    phone: "",
    contact_person_title:"",
    contact_number_primary: "",
    contact_number_secondary: "",
    email: "",
    website: "",
    address_line1: "",
    address_line2: "",
    state: 0,
    district: 0,
    pincode: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [optDist, setOptDist] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    //setFormValues({ ...formValues, [name]: value});
    if (name === "phone" || name === "pincode" || name === "contact_number_primary" || name === "contact_number_secondary" ) {

      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
      console.log("Inside check ******************");
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
  };
  const [optionsState, setOptionsState] = useState([]);
  const [stateIdSelect, setStateIdSelect] = useState({});

  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if ( retStateDist ){
      setDistrictIdSelect(distArrRet[0]);
      setOptDist(distArrRet);
    }
  };
  
  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };


  const  getCompany = async () => {
    var retState = false;
    var companyInfoTmp = {};
    console.log("getCompany api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/core/getCompany", { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data[0]);
        //setCompanyList(res.data.detail.data[0]);
        //setShowMsgTitle("Company Info Added Successfully");
        companyInfoTmp = res.data.detail.data[0];
        setFormValues(res.data.detail.data[0]);

        /*
        var findDist = distArrRet.find(element => element.value === res.data.detail.data[0].district_id);
        if ( findDist !== undefined ) {
          console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
          setDistrictIdSelect({value: res.data.detail.data[0].district_id, label: findDist.label});
        }

        var findState = stateArrRet.find(element => element.value === res.data.detail.data[0].state_id);
        if ( findState !== undefined ) {
          console.log("++++++++++++++++++------------------- FOUND STATE : ", findState);
          setStateIdSelect({value: res.data.detail.data[0].state_id, label: findState.label});
        }
        */
        //setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retState, companyInfoTmp];
  };


  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateCompany called ##########################");
    
        //console.log("PASSED STATE VALUE IN Customer Add/Update page : ", state);
        //var stateIdInput = 1;
        /*
        var stateNameInput = "TAMIL NADU";
        var retStateDist = false;
        var distArrRet = [];
        var retStateDist2 = false;
        var distArrRet2 = [];
        
        const getDistrictListWrapper = async (stateIdInput) => {
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput);
          console.log("RETURNED ARRAY : ", distArrRet);
          return [retStateDist, distArrRet];
        }

        var country_id = appGlobal.COUNTRY_ID;
        var retStateStList = false;
        var stateArrRet = [];
        const getStateListWrapper =  async() => {
           [retStateStList, stateArrRet] = await getStateList(country_id);
           console.log("retStateStList", retStateStList,  ", retStateStList : " , stateArrRet);
           setOptionsState(stateArrRet);
        }
        */
        var stateIdInput = appGlobal.DEFAULT_STATE;
        ( async() =>{
          
          var [retStateCompany, companyInfoTmp] = await getCompany();
          if ( retStateCompany ) {
          //await getDistrictListWrapper(companyInfoTmp.state_id);
          stateIdInput = companyInfoTmp.state_id
          var [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

          if ( retStateDist ) {
            console.log("DISTRICT FETCH ARRAY :", distArrRet);
            setOptDist(distArrRet)
            var findDist = distArrRet.find(element => element.value === companyInfoTmp.district_id);
            if ( findDist !== undefined ) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect({value: companyInfoTmp.district_id, label: findDist.label});
          }
          }
          //await getStateListWrapper();
          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);

          if(retStateStList){
            console.log("STATE FETCH ARRAY :", stateArrRet);
            setOptionsState(stateArrRet); 
            var findState = stateArrRet.find(element => element.value === companyInfoTmp.state_id); 
          }
          if ( findState !== undefined ) {
            console.log("++++++++++++++++++------------------- FOUND STATE : ", findState);
            setStateIdSelect({value: companyInfoTmp.state_id, label: findState.label});
          }
          setToRender(true);
        }

        })();




        
        /*
        (async() => {
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput, stateNameInput);
        })();
        */
        /*
        var country_id = appGlobal.COUNTRY_ID;
        var retStateStList = false;
        var stateArrRet = [];
        const getStateListWrapper =  async() => {
           [retStateStList, stateArrRet] = await getStateList(country_id);
           console.log("retStateStList", retStateStList,  ", retStateStList : " , stateArrRet);
           setOptionsState(stateArrRet);
        }

        (async() => {
          await getStateListWrapper();
        })();  
      */
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      //console.log("distArrRet ^^^^^^^^^^^^^^^^ ARAYA:", distArrRet);
      //console.log("stateArrRet_____________________:", stateArrRet);

      /*
      const getCompanyWrapper = async() => {
        await getCompany(distArrRet, stateArrRet);
      }

      (async() => {
        await getCompanyWrapper();
      })();
      */

      initialized.current = true;
    }
  }, []);

  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}

      
      var apiToCall = "/core/updateCompany";
     
      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"],
        contact_person_title: formValues["contact_person_title"] || "",
        gst_no: formValues["gst_no"],
        phone: formValues["phone"],
        contact_number_primary: formValues["contact_number_primary"] || "",
        contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"],
        address_line2: formValues["address_line2"],
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"],
      };

      
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: client.withCredentialState });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
      setSubmitAction(false);
    }
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = "Company Name is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.contact_person) {
      errors.contact_person = "Contact Person is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.address_line2) {
      errors.address_line2 = "City / Town is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.contact_number_primary) {
      errors.contact_number_primary = "Primary Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
      //setValidateError(true);
    }


    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit clicked at AddUpdateCompany : ", e);
    setIsReadOnly(false);
  };
  
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowDialog(false);
    //navigate("/listCustomers");
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/ListCustomers");
  };
  

  return (
    <>
    
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Company</span>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
            >
              <div className="d-flex justify-content-end">
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-company-name`}
              id={`edit-icon-company-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                </div>
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
            <Row>
            <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}></Col>
              
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label > Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="dispname-id"
                    name="display_name"
                    placeholder="Display Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["display_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.display_name}</p>
              </Col>
             
            </Row>
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    GST  Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="gst-no-id"
                    name="gst_no"
                    placeholder="GST / PAN Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["gst_no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.gst_no}</p>
              </Col> 
            <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    Contact Person
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact-person-id"
                    name="contact_person"
                    placeholder="Contact Person"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label >
                    Contact Person Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="cont-person-title-id"
                    name="contact_perosn_Title"
                    placeholder=" Owner / Supervisor"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_perosn_title"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person_title}</p>
              </Col>
            </Row>
            <br></br>
            <h5>Contact Details</h5>
            <hr align="center" />
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone-id"
                    name="phone"
                    //className="mobNum"
                    placeholder="Phone Number"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["phone"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"
                >
                  <Form.Label className="field required">
                    Primary Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="prim-mob-id"
                    name="contact_number_primary"
                    //className="mobNum"
                    placeholder="Primary Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["contact_number_primary"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_number_primary}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"
                >
                  <Form.Label>Secondary Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="Sec-mob-id"
                    name="contact_number_secondary"
                    //className="mobNum"
                    placeholder="Secondary Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["contact_number_secondary"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_number_secondary}</p>
              </Col>  
            </Row>
           
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    id="Web-id"
                    name="website"
                    placeholder="Website"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["website"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.website}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-1-id"
                    name="address_line1"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-2-id"
                    name="address_line2"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Select
                    id="district"
                    name="district"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optDist}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.districtId}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state1"
                    name="state1"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect,
                    }}
                    options={optionsState}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.setStateId}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label  className="field required">Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode-id"
                    name="pincode"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            {!isReadOnly && (
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction && 
                      <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    <span className="visually-hidden">Loading...</span>
                    </>  
                    }
                    Save
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
            )} 
             <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateBusiness;
