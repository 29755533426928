import React, { Fragment, useState } from 'react';
import { Alert } from 'react-bootstrap';
import logo_avn from './assets/images/logo_avs.png';

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from "./pages/Auth";
import CsvGenerate from './pages/CsvGenerate';


import { client } from './config/Config';
import axios from 'axios';

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import ListTasks from './pages/ListTasks';
import AddUpdateTask from './pages/AddUpdateTask';
import PrintTask from './pages/PrintTask';

import ListMyTasks from './pages/ListMyTasks';
import AddUpdateTaskProgress from './pages/AddUpdateTaskProgress';

import SplashScreen from './pages/SplashScreen';
import AddUpdateBusiness from './pages/AddUpdateBusiness';

import ListMyWorkLogs from './pages/ListMyWorkLogs';
import AddUpdateMyWorkLog from './pages/AddUpdateMyWorkLog';

import ListBugs from './pages/ListBugs';
import AddUpdateBug from './pages/AddUpdateBug';

import ListTickets from './pages/ListTickets';
import AddUpdateTicket from './pages/AddUpdateTicket';

import ListWorkLogs from './pages/ListWorkLogs';
import AddUpdateWorkLog from './pages/AddUpdateWorkLog';

import ListMyTimeSheets from './pages/ListMyTimeSheets';


//import { useNavigate } from "react-router-dom";

function App() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  //const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);


    let reqLoginPayload = {
      username: username.value,
      password: password.value
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var res = await axios.post(client.domain + '/login', reqLoginPayload, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.res_data);
        console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        console.log("Response message from server aI : ", res.data.detail.res_data.aI);

        localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle('LOGIN FAILED!');
          setShow(true);
        }
      }
    }

  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/auth" element={<Auth />} />

        <Route path="/addUpdateBusiness" element={<AddUpdateBusiness />} />
        <Route path="/listTasks" element={<ListTasks />} />
        <Route path="/addUpdateTask" element={<AddUpdateTask />} />
        <Route path="/printTask" element={<PrintTask />} />
        <Route path="/listMyTasks" element={<ListMyTasks />} />
        <Route path="/addUpdateTaskProgress" element={<AddUpdateTaskProgress />} />
        <Route path="/listMyWorkLogs" element={<ListMyWorkLogs />} />
        <Route path="/addUpdateMyWorkLog" element={<AddUpdateMyWorkLog />} />
        <Route path="/listBugs" element={<ListBugs />} />
        <Route path="/addUpdateBug" element={<AddUpdateBug />} />
        <Route path="/listTickets" element={<ListTickets />} />
        <Route path="/addUpdateTicket" element={<AddUpdateTicket />} />
        <Route path="/listWorkLogs" element={<ListWorkLogs />} />
        <Route path="/addUpdateWorkLog" element={<AddUpdateWorkLog />} />
        <Route path="/listMyTimeSheets" element={<ListMyTimeSheets />} />
        
      </Routes>
    </HashRouter>
  );
}
export default App;
