import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
//import Sidebar from "./Sidebar";
//import Sidebar2 from "./Sidebar2";
//import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal} from "../config/Config";
import axios from "axios";

//import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import InputGroup from 'react-bootstrap/InputGroup';

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsCardText
} from "react-icons/bs";
import { MdDelete } from "react-icons/md";

import SidebarMain from "./SidebarMain";
import { formatDateIndian } from "./utils";

const AddUpdateTaskProgress = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("**************props:", props);
  /*
  let taskArr = [

    {
    
     date: "12-09-2023",
     project: "Readymix",
     priority: "Image added",
     status: "completed",
     assigned_to: "Kavi",
     due_date: "12-12-2023",
     comment: "Finished",
     update_on:"12-09-2023",
     update_by:"Ram"
    },
   
  ];
  */

  const activityArr = [
    { value: 1, label: "Coding" },
    { value: 2, label: "Designing" },
    { value: 3, label: "Analyze" },
  ];

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [disableState, setDisableState] = useState(true);
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [compositionUpdate, setCompositionUpdate] = useState(false);
  
 

  let initialItems = [];
 
 
  const [items, setItems] = useState(initialItems);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    due_date: formatDate(new Date()),
    work_date: formatDate(new Date()),
    task_summary: "",
    task_description: "",
    task_sno: "",
    project_name: "",
    minute_spent: "",
    minute_remaining: "",
    hour_spent: "",
    hour_remaining: "",
    work_description: "",

	  
    
  };



  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Oustanding Saved Successfully');
 
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
 
  const [toRender, setToRender] = useState(false);

 
  const [editAction, setEditAction] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);


  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  const [submitDelAction, setSubmitDelAction] = useState(false);
  const [dialogResStatus, setDialogResStatus] = useState("");
  const [showMsgTitle, setShowMsgTitle] = useState("");
  const [showMsgBody, setShowMsgBody] = useState("");
  const [projectIdSelect, setProjectIdSelect] = useState({});
  const [optProject, setOptProduct] = useState([]);
  const [priorityIdSelect, setPriorityIdSelect] = useState({});
  const [optPriority, setOptPriority] = useState([]);
  const [statusIdSelect, setStatusIdSelect] = useState({});
  const [optStatus, setOptStatus] = useState([]);
  const [assignToIdSelect, setAssignToIdSelect] = useState({});
  const [optAssignTo, setOptAssignTo] = useState([]);
  const [activityIdSelect, setActivityIdSelect] = useState({});
  const [optActivity, setOptActivity] = useState(activityArr);

 

  const [actionVal, setActionVal] = useState('save-only');
  const [isReadOnlyAssignedTo, setIsReadOnlyAssignedTo] = useState(false);

  var validateErr = false;


  
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [enableState, setEnableState] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
   
    setFormValues({ ...formValues, [name]: value });
    
  };

  const getStaticDataCreateTask = async() => {
    var dataCreateArr = [];
     var retState = false;
     try {
       var aIVal = localStorage.getItem("_aI");
       var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
       var res = await axios.get(client.domain + "/pmo/getStaticDataCreateTask", { headers }, { withCredentials: false });
       console.log("Response from server getStaticDataCreateTask : ", res.data.detail);
 
       if (res.data.detail.status === "SUCCESS") {
         console.log("Response message from server getStaticDataCreateTask : ", res.data.detail.data);
 
 
         /*
         res.data.detail.data.map((data) => {
           var dataInd = { id: data, label: data };
           dataCreateArr.push(dataInd);
         })
         */
         
       
 
        if ( state.origin === 'listMyTasks' ) {
        setOptStatus(res.data.detail.data.status);
        if (state.action === 'view' || state.action === 'edit') {
         var findStatus = res.data.detail.data.status.find(element => element.value === state.passed_element.form_values.status_code);
         if (findStatus !== undefined) {
           console.log("++++++++++++++++++------------------- FOUND STATUS : ", findStatus);
             setStatusIdSelect(findStatus);
         }
         else {
          console.log("<<<<<<<<<<<<<<<<<<<<<<< FIND FAILED >>>>>>>>>>>>>>>>>>>>");
           setStatusIdSelect(res.data.detail.data.status[0]);
         }
        }
        else{
          setStatusIdSelect(res.data.detail.data.status[0]);
        }
 
        setOptAssignTo(res.data.detail.data.assignedto);
        if (state.action === 'view' || state.action === 'edit') {
         var findAssignedto = res.data.detail.data.assignedto.find(element => element.value === state.passed_element.form_values.assigned_to_id);
         if (findAssignedto !== undefined) {
           console.log("++++++++++++++++++------------------- FOUND ASSIGNED TO : ", findAssignedto);
             setAssignToIdSelect(findAssignedto);
         }
         else {
           setAssignToIdSelect(res.data.detail.data.assignedto[0]);
         }
        }
        else{
           setAssignToIdSelect(res.data.detail.data.assignedto[0]);
        }
        
         setToRender(true);
 
         retState = true;
      }
       }
     } catch (error) {
       console.log("FULL ERROR : ", error);
       if (error.code === "ERR_NETWORK") {
         console.log("Not able to reach target server please try after sometime");
 
       } else {
         console.log("ERROR : ", error.response.data.detail);
         if (error.response.data.detail.status === "FAILED") {
           console.log("Request failed due to ", error.response.data.detail.data);
 
         }
       }
     }
     return [retState, dataCreateArr]
  };

  const updateTask = async () => {
    console.log("updateTask api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}` };

      var reqParam = {
        
          task_id: formValues["id"],
          assigned_to: assignToIdSelect.value,
          status_code: statusIdSelect.value,
          comment: formValues["comment"] || "",
        //old_password: formValues["old_password"],
        //new_password: formValues["new_password"]
      };
     

      var res = await axios.post(
        client.domain + "/pmo/updateTaskProgress",
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);

        setAlertPurpose('update');
        setAlertTitle('Success');
        setAlertBody(res.data.detail.data.message);
        setDialogResStatus('success');
        setSubmitDelAction(false);
        setShowAlert(true);
        /*
        setFormValues(res.data.detail.data);
        setShowMsg("Task successfully...!");
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
        */
      }
      else {

        setAlertPurpose('update_failed');
        setAlertTitle('Failed');
        setAlertBody("Progress Update Failed!");
        setDialogResStatus('failure');
        setSubmitDelAction(false);
        setShowAlert(true);
        /*
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Alert!");
        setShowDialog(true);
        setSubmitAction(false);
        */
      }
    } catch (error) {


      console.log("FULL ERROR : ", error);
      setAlertPurpose('update_failed');
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setDialogResStatus('failure');

     
      //setSubmitDelAction(false);
      setShowAlert(true);

     
    }
  };


  const taskHistory = async () => {
    console.log("taskHistory api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}` };

      var reqParam = {
        
          task_id: formValues["id"],
      };
     

      var res = await axios.post(
        client.domain + "/pmo/getTaskHistory",
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setTaskList(res.data.detail.data);
          //setCustomerListFull(res.data.detail.data);
          //setShowMsgTitle("Task Added Successfully!");
        /*
        setAlertPurpose('update');
        setAlertTitle('Success');
        setAlertBody(res.data.detail.data.message);
        setDialogResStatus('success');
        setSubmitDelAction(false);
        setShowAlert(true);
        */
       
      }
      else {
        /*
        setAlertPurpose('update_failed');
        setAlertTitle('Failed');
        setAlertBody("Progress Update Failed!");
        setDialogResStatus('failure');
        setSubmitDelAction(false);
        setShowAlert(true);
        */
       
      }
    } catch (error) {


      console.log("FULL ERROR : ", error);
     // setAlertPurpose('update_failed');
      //setAlertTitle("Warning");
      //setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      //setDialogResStatus('failure');

     
      //setSubmitDelAction(false);
      //setShowAlert(true);

     
    }
  };

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);
  };

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "minute_spent" || name === "minute_remaining" || name === "hour_spent" || name === "hour_remaining")  {
      console.log("Inside check ******************");
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    
    else {
      setFormValues({ ...formValues, [name]: value });
    }

     // setFormValues({ ...formValues, [name]: value });
   
  };
  /*
  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("handleOnItemChangeComposition called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, time_spent: value});
    setFormValues({ ...formValues, time_remaining: value});
    setFormValues({ ...formValues, work_description: value});
  };
  */
  

 
  

 
 

  useEffect(() => {
    if (!initialized.current) {
        console.log(
          "*************** useEffect::addUpdateTaskProgress called ##########################"
        );
        
  
        if (state === null) {
          navigate("/listMyTasks");
        } else {
          console.log("PASSED STATE VALUE IN TASK Add/Update page : ", state);
           console.log("Inside else for view or edit ...");
           console.log("CALLED CURRENT PAGE : ", document.referrer);
           setActivityIdSelect(optActivity[0]);
              //setFormValues(state.passed_element);
              setFormValues({ ...formValues, id: state.passed_element.form_values.id, task_summary: state.passed_element.form_values.task_summary, description: state.passed_element.form_values.task_description, task_sno: state.passed_element.form_values.task_sno, project_name: state.passed_element.form_values.project_name,
                 assigned_to_name: state.passed_element.form_values.assigned_to_name,
                 task_description: state.passed_element.form_values.task_description, task_sno:state.passed_element.form_values.task_sno
              });

              if ( state.origin === 'addUpdateTask' ) {
                setOptAssignTo(state.passed_element.full_response.assignedto);
                if (state.action === 'add') {
                  setAssignToIdSelect(state.passed_element.full_response.assignedto[0]);
                }
                else if ( state.action === 'edit' || state.action === 'view') {
                  console.log("BEFORE SEARCH ASSIGNED TO: ", state.passed_element.form_values.assigned_to_id);
                  var findAssignedTo = state.passed_element.full_response.assignedto.find(element => element.value === state.passed_element.form_values.assigned_to_id);
                  console.log("**************** findASSIGNEDTO value ++++++++++++ : ", findAssignedTo);
                  if (findAssignedTo !== undefined) {
                    setAssignToIdSelect(findAssignedTo);
                  }
                }
                else {
                  setAssignToIdSelect(state.passed_element.full_response.assignedto[0]);
                }

                setOptStatus(state.passed_element.full_response.status);
                if (state.action === 'add') {
                  setStatusIdSelect(state.passed_element.full_response.status[0]);
                }
                else if ( state.action === 'view' || state.action === 'edit') {
                  console.log("BEFORE SEARCH STATUS: ", state.passed_element.form_values.status_code)
                  var findStatus = state.passed_element.full_response.status.find(element => element.value === state.passed_element.form_values.status_code);
                  console.log("**************** findSTATUS value ++++++++++++ : ", findStatus);
                  if (findStatus !== undefined) {
                    setStatusIdSelect(findStatus);
                  }
                }
                else {
                  setStatusIdSelect(state.passed_element.full_response.status[0]);
                }
              }
              /*
          if (state.action === "add") {
            setIsReadOnly(true);
          }
          else {
            setFormValues(state.passed_element);
          }
          console.log("newObj :", formValues);
          if (state.action === "add") {
            setIsReadOnly(false);
          }
           */
          //setFormValues(state.passed_element);
          setToRender(true);
        }

        (async() => {
          //if ( state.origin === 'listMyTasks' ) {
            await getStaticDataCreateTask();
          //}
        })();
       
        
        
        initialized.current = true;
      }
    /*
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);

      
      
      if (state === null) {
        navigate("/listTasks");
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);
        //setDateIdSelect(optionsDate[0]);
        
        initialized.current = true;
      }
      if (state.action === "add") {
        setIsReadOnly(true);
      }
      else {
        setFormValues(state.passed_element);
      }
      
      console.log("PASSED VALUE THROUGH STATE variable : ", state);
      //setDateIdSelect(optionsDate[0]);
      initialized.current = true;
     

    }
    */
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
  
    console.log("Form submit called ...");

    console.log("FORM VALUES at handleChange func : ", formValues);

    console.log("CUSTOMER : ", formValues["customer_name"]);
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }
    
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
    if (!values.comment){ 
      errors.comment = "Comments is required!";
      validateErr = true;
    }

    if (!values.work_description){ 
      errors.work_description = "Work Description is required!";
      validateErr = true;
    }

    if (!values.minute_spent){ 
      errors.minute_spent = "Time Spent is required!";
      validateErr = true;
    }

    if (!values.hour_spent){ 
      errors.hour_spent = "Time Spent is required!";
      validateErr = true;
    }

   
    return errors;
  };



 

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
 
 

  const handleDelete = async (e) => {
    console.log("Handle Delete clicked : ", e);
    console.log("Handle Delete clicked current target ID: ", e.currentTarget.id);

    setAlertPurpose('delete');
    //setDialogResStatus('delete');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this Customer Statement!");
    setShowAlert(true);

    //setIsReadOnly(false);

    //await deleteCustOsStmt();
  };

 
  
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };
  
  
  const handleSave = async () => {
    //event.preventDefault();
    //setActionVal(actionVal);
    
  
  };

  const handleUpdate = async (e) => {

    
    e.preventDefault();
    console.log("handle Update called : ", e);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      updateTask();
     
    }
    //updateTask();
    
  };
  

 
  const handleAlertClose = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listMyTasks");
    }
  }
 /*
  const callApiToGenerateOS = async() => {
    console.log("callApiToGenerateOS called ...: ", actionVal);

    var summaryUpdateArr = [];
    summaryList.map((summary) => {
      console.log("SUMMARY INDIVIDUAL : ", summary);
      console.log("INDIVIDUAL SUMMARY ID : ", summary.id);
      summaryUpdateArr.push(summary.id);
    });

    var receiptArr = [];
    receiptList.map((receipt) => {
      console.log("RECEIPT INDIVIDUAL : ", receipt);
      console.log("RECEIPT ID : ", receipt.id);
      receiptArr.push(receipt.id);
    });

    let reqAddUpdateOsStmt = {
      os_date: formValues.os_date,
      customer_id: formValues.customer_id,
      previous_balance: customerOs.previous_balance,
      total_received: receipt_total_sum.current,
      current_balance: total_amount_sum.current,
      total_balance: total_balance_os.current,
      summary_array: summaryUpdateArr,
      receipt_array: receiptArr
    };

   
    console.log("payload addUpdateDC :", reqAddUpdateOsStmt);

    //var testTkn = "UJDFISDHjkdah8473bjkhgjhf";
    //var headers = {...client.headers,Authorization: " " + testTkn,};

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      if ( actionVal === 'save-print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }

     
      //setSubmitAction(true);
      setAlertPurpose('submit');
      var res = await axios.post(client.domain + "/insertCustOsStmt", reqAddUpdateOsStmt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);

        if ( action_val_ref.current === 'save-print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        //setAlertDialogTitle("SUCCESS!");
        //setAlertDialogBody("Outstanding Summary Created Successfully ...");
        //setAlertPurpose("success");
        //setShowAlert(true);
        //setSubmitAction(false);

        if ( action_val_ref.current === 'save-print' ) {
          console.log(">>>>>>>>>>>>>>>> INSIDE SAVE AND PRINT >>>>>>>>>>>>>>>>>>>>>>>");
          setAlertPurpose("success");
          var urlStr = window.location.href;
          var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
          var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
      
          console.log("CALLED URL : ", urlTmp);
          console.log("CALLED PAGE : ", paramsTmp);
          
          //window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?order_id=${passedValue.order_id}`);
          var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?os_stmt_id=${res.data.detail.res_data.os_stmt_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
          winPrint.moveTo(0,0);
          winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
          navigate(-1);
        }
        else {
          setAlertPurpose("success");
          setAlertTitle("Success");
          setSubmitAction(false);
          setAlertBody("Outstanding Statement Generated Successfully!"); 
          setShowAlert(true); 
        }
        
      }
      else {
        setAlertPurpose("failure");
        setAlertTitle("Failed");
        setAlertBody("Outstanding Statement Generated Failed!");
        setShowAlert(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
        setAlertPurpose("failure");
        setAlertTitle("Warning");
        setAlertBody("Unable to process your request at this moment.Please try after sometime!");
        setShowAlert(true);
        setSubmitAction(false);
    }
  }
  */

  const handleAlertOK = async(e) => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Handle alert close called ...: ", alertPurpose);
    setShowAlert(false);
    setSubmitAction(false);
    
    if( alertPurpose === 'submit') {
      //callApiToGenerateOS();
    }
    else if ( alertPurpose === 'success') {
      navigate('/listCustOsStmt');
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'confirmed') {
        //callApiToGenerateOS();
      }
    
   
    else if ( alertPurpose === 'update' ) {
      //await deleteCustOsStmt();
    }
    else if ( alertPurpose === 'update' ) {
      navigate('/listMyTask');
    }
    else if ( alertPurpose === 'update_failed') {

    } 
  }

  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowAlert(false);
    setSubmitAction(false);
    if ( alertPurpose === 'success') {
      navigate('/listCustOsStmt');
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
  };
 
 
  
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  /*  
  const handleProjectSelect = (e, data) => {
    console.log("handleProjectSelect called ...", e);
  };
  const handlePrioritySelect = (e, data) => {
    console.log("hadlePrioritySelect called ...", e);
  };
  */

  const handleStatusSelect = (e, data) => {
    console.log("handleStatusSelect called ...", e);
    if ( e.value === 'TODO') {
      var findAssignedTo = optAssignTo.find(element => element.value === 0);
        console.log("**************** findUOM value ++++++++++++ : ", findAssignedTo);
        if (findAssignedTo !== undefined) {
          setAssignToIdSelect(findAssignedTo);
          setIsReadOnlyAssignedTo(true);
        }
    }
    else {
      var findAssignedTo = optAssignTo.find(element => element.value !== 0);
        console.log("**************** findUOM value ++++++++++++ : ", findAssignedTo);
        if (findAssignedTo !== undefined) {
          console.log("Non default value : ", findAssignedTo)
          setAssignToIdSelect(findAssignedTo);
        }
      setIsReadOnlyAssignedTo(false);
    }
    setStatusIdSelect({value: e.value, label: e.label});
  };

  const handleAssignSelect = (e, data) => {
    console.log("handleStatusSelect called ...", e);
    setAssignToIdSelect({value: e.value, label: e.label});
  };


  const handleActivitySelect = (e, data) => {
    console.log("handleActivitySelect called ...", e);
    setActivityIdSelect({value: e.value, label: e.label});
  };


  const handleDueDateChange = async (e) => {
    console.log("handleDueDateChange called ...", e);
    console.log("handleDueDateChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, due_date: e.target.value });
  };

  const handleSwitchChange = async (e) => {
    console.log("handle on change called : ", e);
    setEnableState(!enableState);
    //setEnableState(true);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    if ( e.target.checked ) {
       await taskHistory();
    }
   /*
    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      taskList[currentId]
    );

    setSelectedId(currentId);   

    setShowDialog(true);
    */
  };

  const handleWorkLog = async (e) => {
  setShowConfigDialog(true);
    console.log("Handle Worklog clicked  : ", e);
    console.log("Handle Worklog clicked currentTarget : ", e.currentTarget);
    console.log("Handle Worklog clicked  target : ", e.target);           
    const { name, value } = e.currentTarget;

  };
  
  const handleWorkDateChange = async (e) => {
    console.log("handleWorkDateChange called ...", e);
    console.log("handleWorkDateChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, work_date: e.target.value });
  };


 
 

  return (
    <>
          <Container>
            <SidebarMain />
            { toRender ?
             (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    Task Progress
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                className="d-flex justify-content-end float-end"
                >
               

                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-task-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-task-name`}
                      id={`back-icon-task-id`}
                    />
                  </Button>
                 
                      &nbsp;&nbsp;
                
                      <Button
                        className="btn btn-primary"
                        id="btn-worklog-task-id"
                        name="btn-worklog-task-name"
                        onClick={handleWorkLog}
                      >
                          Work Log
                           {editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                      </Button>
                   {/*
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                      
                      &nbsp;&nbsp;

                      <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                      <BsPencilSquare
                        key={`edit-btn-task-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-task-id`}
                      />
                    </Button>
                    
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                 
                  &nbsp;&nbsp;
                      <Button
                        variant="danger"
                        className="btn btn-danger btn-md"
                        onClick={(e) => handleDelete(e)}
                        disabled={submitDelAction ? true: false}
                      >
                        {submitDelAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }

                        <MdDelete
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                      
                    </>
                  )}
                   */}
                </Col>
              </Row>

              <p></p>

              <div className="ui-form">
                <hr align="center" />
                
               <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "32px"}}>
                     Task # :
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formValues.task_sno}
                      </Form.Label>
                       </Col>
               </Row>
               <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "28px"}}>
                     Project :
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formValues.project_name}
                      </Form.Label>
                       </Col>
               </Row>
               <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Assigned To:
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formValues.assigned_to_name}
                      </Form.Label>
                       </Col>
                 </Row>
                 <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "13px"}}>
                     Due Date :
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formatDateIndian(new Date(formValues.due_date))}
                      </Form.Label>
                       </Col>
                 </Row>
                 <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold',paddingLeft: "45px"}}>
                     Task :
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formValues.task_summary}
                      </Form.Label>
                       </Col>
                 </Row>

                 <Row>
               <Col xs={"1"} sm={"1"} md={"1"} lg={"1"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Description :
                      </Form.Label>
                      </Col>
                      <Col xs={"3"} sm={"3"} md={"3"} lg={"3"} style={{alignContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        
                        {formValues.task_description}
                      </Form.Label>
                       </Col>
                 </Row>
                 <hr align="center" />
                
                 <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className="d-flex justify-content-start">
                      <h6>
                        <Badge pill bg="secondary">
                          Progress Update
                        </Badge>
                      </h6>
                      </div>
                      </Col>
                      </Row>


                      <div className="ui-form"></div>

                     <Row className="show-grid">

                      <Col xs={3} md={3} sm={3} lg={3}>
                      <Row>
                      <div style={{width: '220px'}}>
                      <Col xs={12} md={12} sm={12} lg={12}>
                      <Form.Group className="mb-3">
                      <Form.Label style={{paddingBottom:"5px"}}>Status</Form.Label>
                      <Select
                      inline
                      id="status"
                      name="status"
                      value={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      options={optStatus}
                      defaultValue={{
                        value: statusIdSelect.value,
                        label: statusIdSelect.label,
                      }}
                      onChange={handleStatusSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                        </Col>
                        </div>
                        </Row>
                        <Row>
                          <div style={{width: '220px'}}>
                        <Col xs={12} md={12} sm={12} lg={12}>
                        <Form.Group className="mb-3">
                       <Form.Label style={{paddingBottom:"8px"}}>Assigned To</Form.Label>
                        <Select
                        inline
                        id="assign_to"
                        name="ssign_to"
                        value={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      options={optAssignTo}
                      defaultValue={{
                        value: assignToIdSelect.value,
                        label: assignToIdSelect.label,
                      }}
                      onChange={handleAssignSelect}
                      isDisabled={isReadOnlyAssignedTo}
                      //style={{paddingBottom:"60px"}}
                    />
                    </Form.Group> 
                          </Col>
                          </div>
                          </Row>
                          </Col>
                          <Col xs={9} md={9} sm={9} lg={9}>
                            <Col>
                          <Form.Group className="mb-3">
                        <Form.Label className="field required">Comments</Form.Label>
                    <Form.Control
                    as="textarea" rows={5}
                    //type="text" rows={6}
                    name="comment"
                    id="comment"
                    placeholder="Comment"
                    value={formValues.comment}
                    onChange={handleValueChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                </Col>
                <p className="error-msg">{formErrors.comment}</p>
                </Col>
                
                </Row>

                  <Row>
                  <Col xs={12} md={12} sm={12} className="d-flex justify-content-first">
                   
                    <Button className="btn btn-primary" 
                    onClick={(e) => handleUpdate(e, 'save-only')}
                    //disabled={submitDisabled}
                    //disabled={submitAction ? true: false}
                    >
                    {/*
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  */}
               
                  Update
                  </Button>
                    
                  </Col>
                </Row>
              <hr align = "center"/>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex justify-content-start">
                      <h5>
                        <Badge pill bg="secondary">
                          View History
                        </Badge>
                      </h5>
                     
                      &nbsp; &nbsp; &nbsp;
                      <Form.Check // prettier-ignore
                      key={`task-switch-key`}
                      type="switch"
                      id={`task-switch`}
                      name={`task-switch`}
                      onChange={handleSwitchChange}
                      readOnly={isReadOnly}
                      //onStyle="outline-dark" offstyle="outline-light" style="border"
                      size="lg"
                      //width={100} height={75}
                      //style={{ width ="100px" ,height ="75px"}}
                    
                      //checked="true"
                    ></Form.Check>
                     </div>
                    </Col>
                      </Row>
                      {!enableState && (
                        <Col xs={10} sm={10} md={10} lg={10}>
                      <Table striped bordered hover>
                <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Assigned To</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}> Due Date</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Priority</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Status</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Comment</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Updated On</th>
                  <th className="table-row-heading" style={{textAlign: "center",fontSize: "12px"}}>Updated By</th>
                  </tr>
              </thead>
              <tbody>
                {taskList.map((task, idx) => (
                  <tr>
                    <td style={{textAlign: "right"}}>{idx + 1}</td>
  
                    <td style={{textAlign: "left",fontSize: "12px"}}>{task.assigned_to}</td>
                    <td style={{textAlign: "left",fontSize: "12px"}}>{formatDateIndian(new Date(task.due_date))}</td>
                    <td style={{textAlign: "left",fontSize: "12px", color: task.priority_cc}}>{task.priority}</td>
                    <td style={{textAlign: "left",fontSize: "12px", color: task.status_cc}}>{task.status}</td>
                    <td style={{textAlign: "left",fontSize: "12px",width: "390px"}}>{task.comment}</td>
                    <td style={{textAlign: "left",fontSize: "12px"}}>{formatDateIndian(new Date(task.update_on))}</td>
                    <td style={{textAlign: "left",fontSize: "12px"}}>{task.updated_by}</td>
                   
                  </tr>
                ))}
              </tbody>
            </Table>
            </Col>
                      )}


          <Modal 
          show={showConfigDialog}
          size="lg"
          >
          <Modal.Header>
          <Modal.Title>Work Log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <Form> 
            <Row>
                <Col xs={3} md={3} sm={3} lg={3}>

                <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="work_date"
                    name="work_date"
                    //placeholder="Due Date"
                    value={formValues.work_date}
                    onChange={handleWorkDateChange}
                    //readOnly={isReadOnly}
                  />
                </Form.Group>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                      <Form.Group className="mb-3">
                      <Form.Label style={{paddingBottom:"5px"}}>Activity</Form.Label>
                      <Select
                      inline
                      id="activity"
                      name="activity"
                      value={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      options={optActivity}
                      defaultValue={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      onChange={handleActivitySelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                        </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                
                  <Form.Label className="field required">Time Spent</Form.Label>
                  {/*
                  <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="time_spent"
                    id="time_spent"
                    placeholder=" Time Spent"
                    value={formValues.time_spent}
                    //maxLength={75}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  */}
                 <Row>
                <Col>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="hour_spent"
                    id="hour_spent"
                    placeholder="HH"
                    value={formValues.hour_spent}
                    maxLength={2}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>

                <Col>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="minute_spent"
                    id="minute_spent"
                    placeholder="MM"
                    value={formValues.minute_spent}
                    maxLength={2}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>
                </Row>
              </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Label>Time Remaining</Form.Label>
                  <Row>
                <Col>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="hour_remaining"
                    id="hour_remaining"
                    placeholder="HH"
                    value={formValues.hour_remaining}
                    maxLength={2}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>

                <Col>
                <Form.Control
                    type="text"
                    //as="textarea" rows={3}
                    name="minute_remaining"
                    id="minute_remaining"
                    placeholder="MM"
                    value={formValues.minute_remaining}
                    maxLength={2}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Col>
                </Row>
              </Col>
              </Row>    

                <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Work Description</Form.Label>
                  <Form.Control
                    //type="text"
                    as="textarea" rows={3}
                    name="work_description"
                    id="work_description"
                    placeholder=" Work Description"
                    value={formValues.work_description}
                    //maxLength={75}
                    onChange={handleOnChange}
                    //readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              </Form>
              </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseComposition}>
                  Close
              </Button>
              <Button variant="primary" onClick={handleSaveComposition}>
                  Save
              </Button>
              </Modal.Footer>
              </Modal>            
              </div>
            </Form>
            )
            :
            <div className="container-spinner">
            <div className="vertical-center-spinner">
             {/*<Button variant="primary" disabled>*/}
             <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
       
             {/*</Button>*/}
             </div>
             </div>
          } 
          </Container>
       
      
      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { (alertPurpose == 'submit' || alertPurpose === 'confirmation' || alertPurpose === 'delete' || alertPurpose === 'confirmed') &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateTaskProgress;