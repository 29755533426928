import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    Fragment,
  } from "react";
  import Table from "react-bootstrap/Table";
  import { fragment } from "react";
  
  import Select from "react-select";
  
  import {
    Form,
    Button,
    Row,
    Col,
    Alert,
    Modal,
    Container,
    Spinner,
    Image
  } from "react-bootstrap";
  //import Sidebar2 from "./Sidebar2";
  import SidebarMain from "./SidebarMain";
  import { formatDateIndian } from "./utils";
  
  import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsCardText,
    BsFillPrinterFill
  } from "react-icons/bs";
  
  import "../App.css";
  
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { client } from "../config/Config";
  import logo_avinaerp from '../assets/images/logo_avinaerp.png';
  
  
  function ListWorkLogs() {
    const initialized = useRef(false);
    var navigate = useNavigate();
    const [checkedState, setCheckedState] = useState(false);
   
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
   
    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }
  
    function formatDate(date) {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }
  

    const intialValues = {
      work_date: formatDate(new Date()),
    };


   
    let workArr = [
      {
      
       work_date: "12-09-2023",
       activity: "Coding",
       time_spent:"3:30",
       time_remaining: "1.20",
       work_description: "Completed"
      },

      {
      
        work_date: "11-05-2023",
        activity: "Analyze",
        time_spent:"5:30",
        time_remaining: "1.40",
        work_description: "Completed"
       },
     
    ];

    const activityArr = [
      { value: 1, label: "Coding" },
      { value: 2, label: "Designing" },
      { value: 3, label: "Analyze" },
    ];

    const employeeArr = [
      { value: 1, label: "Arun" },
      { value: 2, label: "Aarthi" },
      { value: 3, label: "Bala" },
    ];
  
   
    const [workList, setWorkList] = useState(workArr);
   
    const [toRender, setToRender] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [show, setShow] = useState(false);
    const [activityIdSelect, setActivityIdSelect] = useState({});
    const [optActivity, setOptActivity] = useState(activityArr);
    const [employeeIdSelect, setEmployeeIdSelect] = useState({});
    const [optEmployee, setOptEmployee] = useState(employeeArr);
    const [showMsg, setShowMsg] = useState("Please login again");
    const [showMsgTitle, setShowMsgTitle] = useState(
      "Invalid authentication Session!"
    );
  
    const [selectedId, setSelectedId] = useState(-1);
  
    const [showDialog, setShowDialog] = useState(false);
    const [formErrors, setFormErrors] = useState({});
   
  
   
    /*
    const getTaskList = async () => {
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      
  
        var res = await axios.get(
          client.domain + "/pmo/getAllTasks",
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", 0);
          setTaskList(res.data.detail.data);
          //setCustomerListFull(res.data.detail.data);
          setShowMsgTitle("Task Added Successfully!");
          setToRender(true);
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
          setShowMsg("Please try after sometime");
          setShowMsgTitle("Network Issue!");
          setShow(true);
        } else {
          console.log("ERROR occured : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            setShowMsg("Please try again ...");
            setShowMsgTitle("Unable to process your request!");
            setShow(true);
            //navigate("/auth");
          } else {
            console.log("Non status ERROR code ...");
            setShowMsg(error.response.data.detail);
            setShowMsgTitle("Unable to process your request at this moment!");
            setShow(true);
          }
        }
      }
    };
    */
    

  
  
    useEffect(() => {
      if (!initialized.current) {
        console.log(
          "*************** useEffect::ListTasks called ##########################"
        );
        //getTaskList();
        initialized.current = true;
        setActivityIdSelect(optActivity[0]);
        setEmployeeIdSelect(optEmployee[0]);
        (async() => {
          //await getStaticDataSearchTask();
        })();

       
         
      }
    }, []);
  
    const handleAdd = async (e) => {
      console.log("handle add  called : ", e);
      navigate("/addUpdateWorkLog", {
        state: { passed_element: [], action: "add" },
      });
    };
  
    const handleView = async (e) => {
      console.log("handle edit  called : ", e);
      console.log(
        "handle edit customers currentTarget called : ",
        e.currentTarget
      );
      console.log("handle edit  target called : ", e.target);
      var selectedTagId = e.currentTarget.id;
      console.log("SELECTED TAG ID: ", selectedTagId);
      if (selectedTagId) {
        var currentId = selectedTagId.substring(
          selectedTagId.lastIndexOf("-") + 1
        );
        var nameOfElement = selectedTagId.substring(
          0,
          selectedTagId.lastIndexOf("-")
        );
        console.log("Name of element VIEW: ", nameOfElement);
        console.log("Current id VIEW : ", currentId);
        
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
          workList[currentId]
        );
        navigate("/addUpdateWorkLog", {
          state: {
            passed_element: workList[currentId],
            action: "view",
          },
        });
        
      }
    };
   
    const handleReset = async (e) => {
      console.log("handle reset clicked : ", e);
      navigate(0);
      //setOptCustomer(customerListFull);
    };
  
    
    const handleWorkLogSearch = async (e) => {
      console.log("handleTaskSearch called : ", e);
      //await getReceiptList(customerIdSelect.value, formValues.start_date, formValues.end_date);
  };

  const handleWorkDateChange = async (e) => {
    console.log("handleWorkDateChange called ...", e);
    console.log("handleWorkDateChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, work_date: e.target.value });
  };

  const handleActivitySelect = (e, data) => {
    console.log("handleActivitySelect called ...", e);
    setActivityIdSelect({value: e.value, label: e.label});
  };

  const handleEmployeeSelect = (e, data) => {
    console.log("handleEmployeeSelect called ...", e);
    setEmployeeIdSelect({value: e.value, label: e.label});
  };
   
    return (
      <Container>
        <SidebarMain />
        
            <Row>
                <Col xs={9} md={9} sm={9} lg={9}> 
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Work Logs</span>
             
               </Col>
               <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
              
                <Button
                  variant="warning"
                  id="add-btn-id"
                  name="add-btn-name"
                  className="justify-content-end float-end"
                  onClick={(e) => handleAdd(e)}
                >
                  New WorkLog
                </Button>
              </Col>
               </Row>
               <hr align="center" />
               <Row>
               <Col xs={2} md={2} sm={2} lg={2} style={{paddingTop: "5px"}}>

                    <Form.Group className="mb-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                    type="date"
                    id="work_date"
                    name="work_date"
                    value={formValues.work_date}
                    onChange={handleWorkDateChange}
                    />
                    </Form.Group>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{paddingBottom:"5px"}}>Employee</Form.Label>
                      <Select
                      inline
                      id="employee"
                      name="employee"
                      value={{
                        value: employeeIdSelect.value,
                        label: employeeIdSelect.label,
                      }}
                      options={optEmployee}
                      defaultValue={{
                        value: employeeIdSelect.value,
                        label: employeeIdSelect.label,
                      }}
                      onChange={handleEmployeeSelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label style={{paddingBottom:"5px"}}>Activity</Form.Label>
                      <Select
                      inline
                      id="activity"
                      name="activity"
                      value={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      options={optActivity}
                      defaultValue={{
                        value: activityIdSelect.value,
                        label: activityIdSelect.label,
                      }}
                      onChange={handleActivitySelect}
                      //isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    </Col>
                   
                    <Col xs={1} md={1} sm={1} lg={1} style={{ paddingTop: "30px" }} >
                      <Button id="search-log-id"
                      size={"md"}
                      variant="success"
                      onClick={(e) => handleWorkLogSearch(e)}>
                      Search
                      </Button>
                    
                      </Col> 
           
            </Row>
            <hr align="center" />
            <div className="ui-divider"></div>
  
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center", width: "10px"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "100px"}}>Date</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "150px"}}>Activity</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10px"}}>Time Spent</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "10px"}}>Time Remaining</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Work Description</th>
                  <th className="table-row-heading" style={{textAlign: "center", width: "20px"}}>Details</th>
                </tr>
              </thead>
              <tbody>
                {workList.map((work, idx) => (
                  <tr>
                    <td style={{textAlign: "right"}}>{idx + 1}</td>
                    <td style={{textAlign: "left"}}>{formatDateIndian(new Date(work.work_date))}</td>
                    <td style={{textAlign: "left"}}>{work.activity}</td>
                    <td style={{textAlign: "right"}}>{work.time_spent}</td>
                    <td style={{textAlign: "right"}}>{work.time_remaining}</td>
                    <td style={{textAlign: "left"}}>{work.work_description}</td>
                    
                    <td style={{textAlign: "center" }}>
                      <BsCardText
                        key={`edit-btn-task-key-${idx}`}
                        size={28}
                        style={{ color: "#0D6EFD", cursor: "pointer" }}
                        onClick={(e) => handleView(e)}
                        name={`edit-icon-task-name-${idx}`}
                        id={`edit-icon-task-id-${idx}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
       
               
        
       </Container>
     
    );
  }
  export default ListWorkLogs
  ;
  